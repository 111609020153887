import { Dictionary } from 'Utils/types/utility';
import { CategoryEntity, NormalizedEntitiesAndUrlKeys, SeoCategoryBreadCrumbItem } from './types';

export const getEntityByUrlKey = (normalized: NormalizedEntitiesAndUrlKeys | null, urlKey: string) => {
    if (!urlKey || !normalized) {
        return null;
    }
    const { urlKeys = {}, entities = {} } = normalized;
    const categoryId = urlKeys[urlKey];
    return entities[categoryId] || null;
};

export const mapEntityToBreadCrumbItem = (
    entities: Dictionary<CategoryEntity>,
    cat: CategoryEntity | null
): SeoCategoryBreadCrumbItem | null => {
    if (!cat) {
        return cat as null;
    }
    const parent = cat.parent ? mapEntityToBreadCrumbItem(entities, entities[cat.parent]) : null;
    return {
        depthLevel: cat.depthLevel + 1,
        name: cat.name,
        url: cat.urlKey,
        parent,
    };
};
