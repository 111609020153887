import { INDEXABLE_FILTER_SELECTION_LIMIT } from './constant';
import { SeoDecisionObject } from './types';
import { FilterType, ListingPageUrlState } from 'Client/redux/products/types';
import { PageType } from 'AppShell/appshell_types';
import { AppliedFilter } from 'Client/components/Generic/FilterAndSortBox/types';
import { isStudioPLP } from 'Client/hooks/UrlManager/pageTypeService';

const filterKeys = Object.keys(FilterType);

const filterLengthsInitialState = filterKeys.reduce((acc, name: FilterType) => {
    acc[name] = 0;
    return acc;
}, {}) as Record<FilterType, number>;
type PathFilterSizes = Record<FilterType, number>;

const getPathFilterLengths = (appliedFilters: AppliedFilter<FilterType>[]): PathFilterSizes =>
    appliedFilters.reduce(
        (acc, filter) => {
            acc[filter.type] = filter.values.length;
            return acc;
        },
        { ...filterLengthsInitialState }
    );

const isFilterNonIndexable = (filterName: FilterType): boolean =>
    [FilterType.brands, FilterType.categories].includes(filterName);
const isFilterIndexable = (filterName: FilterType): boolean => !isFilterNonIndexable(filterName);

const shouldIndexFromIndexableFilters = (lengths: PathFilterSizes) => {
    if (lengths.brands > 0) {
        return false;
    }

    return filterKeys
        .filter(isFilterIndexable)
        .every(filterName => lengths[filterName] <= INDEXABLE_FILTER_SELECTION_LIMIT);
};

const pageUpperLimitBasedOnPageType = (type: PageType) => {
    const NO_LIMIT = Infinity;

    switch (type) {
        case PageType.BRANDS:
        case PageType.LANDING:
        case PageType.NEWPRODUCTS:
        case PageType.CATEGORY:
            return 1;
        default:
            return NO_LIMIT;
    }
};

const shouldIndexBasedOnPageNumber = (urlState: ListingPageUrlState): boolean => {
    const { listingPageType, page = 1 } = urlState;
    return page <= pageUpperLimitBasedOnPageType(listingPageType);
};

const getIndexableFiltersSum = (lengths: PathFilterSizes) => {
    const sumFilters = (sum: number, filterName: string): any => sum + (lengths[filterName] || 0);
    const filtersSum = filterKeys.reduce(sumFilters, 0);
    const indexableExcluded = filterKeys.filter(isFilterNonIndexable).reduce(sumFilters, 0);
    return filtersSum - indexableExcluded;
};

// eslint-disable-next-line complexity
export const createSeoDecisionObject = (urlState: ListingPageUrlState): SeoDecisionObject => {
    const lengths = getPathFilterLengths(urlState.appliedFilters);
    const indexableFiltersSum = getIndexableFiltersSum(lengths);
    const pageNumberCondition = shouldIndexBasedOnPageNumber(urlState);
    const indexableFilterCondition = shouldIndexFromIndexableFilters(lengths);

    const isFilterAndPageSatisfied = indexableFilterCondition && pageNumberCondition;
    const noIndexNoFollow = { shouldIndex: false, shouldFollow: false, indexableFiltersSum };

    switch (urlState.listingPageType) {
        case PageType.ALLPRODUCTS:
            return noIndexNoFollow;

        case PageType.NEWPRODUCTS: {
            return {
                shouldIndex: pageNumberCondition && indexableFiltersSum === 0 && lengths.categories === 0,
                shouldFollow: pageNumberCondition,
                indexableFiltersSum,
            };
        }
        case PageType.BRANDS: {
            return {
                shouldIndex: pageNumberCondition && indexableFiltersSum === 0 && lengths.categories <= 0,
                shouldFollow: pageNumberCondition,
                indexableFiltersSum,
            };
        }

        case PageType.LANDING: {
            if (
                Object.values(lengths).some(filtersAmount => filtersAmount > 0) ||
                isStudioPLP(urlState.listingPageKey)
            ) {
                return noIndexNoFollow;
            }
            return {
                shouldIndex: isFilterAndPageSatisfied,
                shouldFollow: isFilterAndPageSatisfied,
                indexableFiltersSum,
            };
        }

        case PageType.CATEGORY:
        default: {
            if (indexableFiltersSum === 2) {
                return {
                    shouldIndex: false,
                    shouldFollow: isFilterAndPageSatisfied,
                    indexableFiltersSum,
                };
            }
            return {
                shouldIndex: isFilterAndPageSatisfied,
                shouldFollow: indexableFilterCondition,
                indexableFiltersSum,
            };
        }
    }
};
