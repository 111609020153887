import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from 'styled-components';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import { isDesktop } from '@westwing/ui-kit/breakPoints';
import { ProductTile, RevisedProductTile } from '@westwing/ui-kit/ProductTile';
import ErrorBoundary from '../Generic/ErrorBoundaries';
import BellaHybridLink from '../Generic/Links/BellaHybridLink';
import useListingPageUrlState from '../ListingPage/hooks/useListingPageUrlState';
import { getSizeSuffix, useSizeSuffix } from './sizeSuffixUtils';
import { connectedWishlistBadgeConfigs } from './WishlistBadge';
import useFilterHeadline from './useFilterHeadline';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import { ProductInterface } from 'Client/redux/products/types';
import StateInterface from 'Client/redux/types';
import useGa from 'Client/hooks/useGa';
import { setFromPLPWithMood, setPDPProductKnownInfoAction } from 'Client/redux/productdetail/actions';
import useElementIntersection from 'Client/hooks/useElementIntersection';
import { ProductListingPages } from 'Client/hooks/UrlManager/types';
import { useCurrentPageType } from 'Client/hooks/UrlManager/pageTypeHooks';
import { PageType } from 'AppShell/appshell_types';
import { useLogger } from 'AppShell/logger/LoggerProvider';
import useConfig from 'AppShell/hooks/useConfig';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { EC_PRODUCT_IMPRESSION_EVENT, ProductList } from 'Common/ga/types';
import { safeRequestIdleCallback } from 'Common/helpers/viewport';
import { OverlayId } from 'Client/components/Overlays/types';
import { OVERLAY_QUERY_PARAM } from 'Client/components/Overlays/useOverlay';
import useBellaPush from 'Client/hooks/useBellaHistoryPush';
import { setIsMoodImageHovered } from 'Client/redux/products/actions';
import { useBellaSelector } from 'Client/redux/hooks';
import { fetchProductSimpleColorVariants } from 'Client/redux/colorVariants/actions';
import PLPColorVariants from 'Client/components/ListingPage/PLPColorVariants';
import useRouter from 'AppShell/hooks/useRouter';
import { getPageTypeByUrl } from 'Client/hooks/UrlManager/pageTypeService';
import useInView from 'Client/hooks/dom/useInView';

const SkuDiverCheckbox = loadable(() => import('../ListingPage/SkuDiver/SkuDiverCheckbox'));

export const productGreyOverlay = css`
    &:after {
        position: absolute;
        content: ' ';
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.colors.skeleton01};
        top: 0;
        left: 0;
        pointer-events: none;
    }
`;

export const productShadow = 'box-shadow: 0 2px 10px rgba(0, 0, 0, 0.14)';
export interface GenericProductProps {
    className?: string;
    product: ProductInterface;
    isHeroProduct?: boolean;
    imageLoading?: 'lazy' | 'eager';
    imageSrc?: string;
    flexBasisDivisor?: number;
    showProductHoverEffect?: boolean;
    'data-testid'?: string;
    onProductHover?: () => void;
    hideNewProductBadge?: boolean;
    list?: ProductList;
    forSingleLookPromotion?: boolean;
    skeletonMode?: boolean;
    isLooksHotspot?: boolean;
}

const infoBoxDesktopFontWhitelistedPages = [
    PageType.HOMEPAGE,
    PageType.CATEGORY,
    PageType.LOOKS,
    PageType.NEWPRODUCTS,
    PageType.PDP,
    PageType.ALLPRODUCTS,
    PageType.LANDING,
    PageType.BRANDS,
];

const Product: React.FC<GenericProductProps> = React.memo(
    // eslint-disable-next-line complexity
    ({
        className,
        product,
        flexBasisDivisor,
        isHeroProduct = false,
        imageLoading = 'lazy',
        showProductHoverEffect = true,
        'data-testid': testId = 'generic-product',
        onProductHover,
        hideNewProductBadge = false,
        list,
        forSingleLookPromotion = false,
        skeletonMode = false,
        isLooksHotspot = false,
    }) => {
        const gaTracking = useGa();
        const dispatch = useDispatch();
        const { pageType } = useCurrentPageType();
        const isListingPage = ProductListingPages.includes(pageType);
        const { position, clickTrackingFn, productMoodImage, link, simpleSku } = product;
        const {
            skuDiver,
            p100PdpShowMoodImageFirst,
            p100ImageOnHoverPlp,
            p100ColorVariantsPlp,
            p100NewProductBadge,
            p100FreistellerImages,
        } = useFeatureToggle();
        const isMoodImageHovered = useSelector<StateInterface, boolean | undefined>(
            state => state.products.isMoodImageHovered
        );
        const colorVariants = useBellaSelector(state => state.products.colorVariants[product.simpleSku]?.data) || [];
        const bellaPush = useBellaPush();
        const [isHover, setIsHover] = useState(false);
        const setIsHoverDesktop = (state: boolean) => {
            if (isDesktop() && showProductHoverEffect) {
                setIsHover(state);
            }
        };
        const {
            location: { pathname },
            host,
        } = useRouter();
        const { pageType: currentPageType } = getPageTypeByUrl(pathname, host);
        const shouldHideNewProductBadge =
            hideNewProductBadge || !p100NewProductBadge || currentPageType === PageType.NEWPRODUCTS;

        const handleOnMouseEnter = (simpleSku: string, link: string) => {
            setIsHoverDesktop(true);
            if (simpleSku && link && isListingPage && !colorVariants.length) {
                dispatch(fetchProductSimpleColorVariants(link, simpleSku));
            }
        };

        const handleOnMouseLeave = () => {
            setIsHoverDesktop(false);
        };

        const couldShowMoodImage = !!(productMoodImage?.path && isListingPage && isHover);
        const showMoodImage = couldShowMoodImage && p100ImageOnHoverPlp;

        const handleClickActions = (e: React.MouseEvent) => {
            if (!link) {
                e.preventDefault();
                return;
            }
            if (clickTrackingFn) {
                clickTrackingFn();
            }
            dispatch(setFromPLPWithMood(!!isMoodImageHovered));
            dispatch(setPDPProductKnownInfoAction(product, !!p100PdpShowMoodImageFirst));
        };

        const handleEfficiencyBadgeClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            e.stopPropagation();
            e.preventDefault();
            handleClickActions(e);
            bellaPush(product.link, false, {
                [OVERLAY_QUERY_PARAM]: OverlayId.ENERGY_EFFICIENCY,
            });
        };

        const impressionWrapperRef = useRef<HTMLDivElement>(null);
        const shouldTrackProductImpression = isListingPage || !!list;

        const onIntersection = useCallback(() => {
            safeRequestIdleCallback(() => {
                gaTracking.addImpression(
                    product,
                    {
                        list: list || ProductList.products,
                        position: position + 1, // Product impression should start from "1" not "0"
                    },
                    isListingPage ? undefined : EC_PRODUCT_IMPRESSION_EVENT
                );
            });
        }, [gaTracking, isListingPage, list, position, product]);

        const intersectionObserverOptions = useMemo(
            () => (isListingPage || !shouldTrackProductImpression ? undefined : { rootMargin: '0px', threshold: 0.6 }),
            [isListingPage, shouldTrackProductImpression]
        );

        useElementIntersection(
            shouldTrackProductImpression ? impressionWrapperRef : undefined,
            onIntersection,
            intersectionObserverOptions
        );

        useInView({
            containerRef: impressionWrapperRef,
            onVisibleCallback: () => {
                if (currentPageType !== PageType.NEWPRODUCTS && product.isNew && !hideNewProductBadge) {
                    gaTracking.trackVariation('p100NewProductBadge', p100NewProductBadge);
                }
            },
            dependency: product.isNew,
        });

        useEffect(() => {
            if (!isMoodImageHovered && couldShowMoodImage) {
                dispatch(setIsMoodImageHovered(true));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [couldShowMoodImage, isMoodImageHovered]);

        useEffect(() => {
            if (isHover && p100ColorVariantsPlp && colorVariants?.length) {
                gaTracking.trackPLP({ action: 'Color variant on hover - impression', label: simpleSku });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [p100ColorVariantsPlp, colorVariants, simpleSku]);

        useEffect(() => {
            if (isHover && onProductHover) {
                onProductHover();
            }
        }, [isHover, onProductHover]);

        const { isSelected: isInWishlist, handleStateChange: handleWishlistStateChange } =
            connectedWishlistBadgeConfigs(product);
        const badgeConfigs = { isInWishlist, handleWishlistStateChange };

        const { staticCmsHost } = useConfig();
        const imageConfigs = {
            loading: imageLoading,
            showMoodImage,
            logger: useLogger(),
            staticCmsHost,
        };

        const [, filterHeadline] = useFilterHeadline();
        const shouldAddSizeSuffix = useSizeSuffix();
        const [urlState] = useListingPageUrlState();
        const title = shouldAddSizeSuffix ? getSizeSuffix(urlState) : '';
        const { sortingParams } = urlState;
        const infoConfigs = {
            handleEfficiencyBadgeClick,
            filterHeadline,
            title,
            sortingParams,
        };
        const featureToggles = useFeatureToggle();
        const configurations = useBellaSelector(({ configurations }) => configurations);
        const shouldShowColorVariants = p100ColorVariantsPlp && isListingPage;
        const translator = useBellaTranslator();

        const Tile = p100FreistellerImages ? RevisedProductTile : ProductTile;

        return (
            <ErrorBoundary boundaryName="Product" fallbackComponent={null}>
                <Tile
                    ref={impressionWrapperRef}
                    data-testid={testId}
                    linkComponent={BellaHybridLink}
                    className={className}
                    isHero={isHeroProduct}
                    flexBasisDivisor={flexBasisDivisor}
                    isWebPImage={p100FreistellerImages}
                    showProductHoverEffect={showProductHoverEffect}
                    onClick={handleClickActions}
                    onMouseEnter={() => handleOnMouseEnter(simpleSku, link)}
                    onMouseLeave={() => handleOnMouseLeave()}
                    product={product}
                    imageConfigs={imageConfigs}
                    badgeConfigs={badgeConfigs}
                    infoConfigs={infoConfigs}
                    featureToggles={featureToggles}
                    configurations={configurations}
                    translator={translator}
                    variantsComponent={
                        shouldShowColorVariants && (
                            <PLPColorVariants
                                isHover={isHover}
                                pdpUrl={link}
                                defaultVariantSku={simpleSku}
                                variants={colorVariants || []}
                                clickFn={clickTrackingFn}
                            />
                        )
                    }
                    hideNewBadge={shouldHideNewProductBadge}
                    forSingleLookPromotion={forSingleLookPromotion}
                    skeletonMode={skeletonMode}
                    isLooksHotspot={isLooksHotspot}
                    infoBoxHasDesktopFont={infoBoxDesktopFontWhitelistedPages.includes(currentPageType)}
                >
                    {skuDiver && <SkuDiverCheckbox sku={product.simpleSku} />}
                </Tile>
            </ErrorBoundary>
        );
    }
);

export default Product;
