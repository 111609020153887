import buildUrl from 'build-url';
import { convertListingPageStateToUrl } from '../listingPageUrlService';
import { SeoListItem } from '../../../Generic/SeoHead/SeoBreadcrumbScript';
import { BREAD_CRUMB_FILTER_WHITELIST, BREADCRUMBS_PATH_FILTERS_UPPER_LIMIT } from './constant';
import { BreadCrumbStateOptions } from './types';
import { createSeoDecisionObject } from './createSeoDecisionObject';
import { capitalize } from 'Client/../common/formatting/formattingService';
import { getEntityByUrlKey, mapEntityToBreadCrumbItem } from 'Client/redux/category/categoriesNormalizer';
import { NormalizedEntitiesAndUrlKeys, SeoCategoryBreadCrumbItem } from 'Client/redux/category/types';
import { FilterType, ListingPageUrlState } from 'Client/redux/products/types';
import { PageType } from 'AppShell/appshell_types';

const getLastSeoBreadcrumbItem = (
    normalized: NormalizedEntitiesAndUrlKeys,
    urlState: ListingPageUrlState,
    seoPageMeta: { pageHeaderTitle: string; urlTitle: string }
) => {
    const { entities = {} } = normalized || ({} as NormalizedEntitiesAndUrlKeys);
    const { pageHeaderTitle, urlTitle } = seoPageMeta;
    const { listingPageKey, listingPageType } = urlState;
    let lastSeoBreadcrumbItem: SeoCategoryBreadCrumbItem | null | undefined = null;
    let listingPageStateForSeoBreadcrumb = urlState;
    let seoBreadCrumbHomePageOffset = 1;

    switch (listingPageType) {
        case PageType.BRANDS: {
            lastSeoBreadcrumbItem = {
                name: pageHeaderTitle,
                url: urlState.listingPageKey,
                parent: {
                    name: urlTitle,
                    url: '',
                    parent: null,
                    depthLevel: ++seoBreadCrumbHomePageOffset,
                },
                depthLevel: ++seoBreadCrumbHomePageOffset,
            };
            listingPageStateForSeoBreadcrumb = {
                ...urlState,
                appliedFilters: [],
                listingPageType,
            };
            break;
        }
        case PageType.CATEGORY: {
            const entity = getEntityByUrlKey(normalized, listingPageKey);
            lastSeoBreadcrumbItem = mapEntityToBreadCrumbItem(entities, entity);
            listingPageStateForSeoBreadcrumb = {
                ...urlState,
                appliedFilters: [],
                listingPageType,
            };
            break;
        }
        default:
            break;
    }

    return { lastSeoBreadcrumbItem, listingPageStateForSeoBreadcrumb };
};

export const buildListForSeoBreadcrumbs = (
    normalized: NormalizedEntitiesAndUrlKeys,
    urlState: ListingPageUrlState,
    seoPage: BreadCrumbStateOptions
) => {
    const { host, pageHeaderTitle, urlTitle = '' } = seoPage;
    const { lastSeoBreadcrumbItem: seoBreadCrumbItem, listingPageStateForSeoBreadcrumb } = getLastSeoBreadcrumbItem(
        normalized,
        urlState,
        { pageHeaderTitle, urlTitle }
    );

    let lastSeoBreadcrumbItem = seoBreadCrumbItem;
    const breadcrumbList: SeoListItem[] = [];
    let showSeoScript = false;
    while (lastSeoBreadcrumbItem) {
        showSeoScript = true;
        breadcrumbList.unshift({
            '@type': 'ListItem',
            position: lastSeoBreadcrumbItem.depthLevel,
            name: lastSeoBreadcrumbItem.name,
            item: convertListingPageStateToUrl(
                { ...listingPageStateForSeoBreadcrumb, listingPageKey: lastSeoBreadcrumbItem.url },
                host
            ),
        });
        lastSeoBreadcrumbItem = lastSeoBreadcrumbItem.parent;
    }
    breadcrumbList.unshift({
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: buildUrl(host),
    });

    // does not violate seo path filters combo
    const { shouldIndex, indexableFiltersSum } = createSeoDecisionObject(urlState);

    if (!shouldIndex) {
        showSeoScript = false;
    }

    // at least one path filter is selected
    const shouldAddToBreadcrumb = indexableFiltersSum <= BREADCRUMBS_PATH_FILTERS_UPPER_LIMIT && shouldIndex;
    if (shouldAddToBreadcrumb) {
        const pathFilters = urlState.appliedFilters.filter(filter => filter.type !== FilterType.query);
        pathFilters.forEach(filter => {
            const isInWhitelist = BREAD_CRUMB_FILTER_WHITELIST.includes(filter.name);
            const name = filter.values ? capitalize(filter.values[0]) : '';

            if (isInWhitelist && name) {
                const url = convertListingPageStateToUrl({ ...urlState, appliedFilters: [...pathFilters] }, host);
                breadcrumbList.push({
                    '@type': 'ListItem',
                    position: breadcrumbList.length + 1,
                    name,
                    item: url,
                });
            }
        });
    }
    return { showSeoScript, breadcrumbList };
};
