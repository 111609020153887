import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showOverlay } from 'Client/redux/header/overlays/actions';
import {
    fetchProductsWishlistAction,
    addToProductsWishlistAction,
    removeFromProductsWishlistAction,
    updateProductsWishlistQueue,
} from 'Client/redux/productsWishlist/actions';
import {
    ADD_QUEUED_PRODUCT_TO_WISHLIST,
    REMOVE_QUEUED_PRODUCT_FROM_WISHLIST,
} from 'Client/redux/productsWishlist/types';
import StateInterface from 'Client/redux/types';
import useLoginAndRegisterOverlayWithTracking from 'Client/hooks/useLoginAndRegisterOverlayWithTracking';
import { OverlayId } from 'Client/components/Overlays/types';

export interface WishListNotificationMessages {
    successMessage: string;
    errorMessage: string;
}

const useProductsWishlist = () => {
    const dispatch = useDispatch();
    const { trackLoginAndRegisterOverlayFomWishlist } = useLoginAndRegisterOverlayWithTracking();
    const isLoggedIn = useSelector<StateInterface, boolean>(state => state.appShellLogin.isLoggedIn);
    const wishlist = useSelector<StateInterface, string[]>(state => state.header.productsWishlist?.products);

    const dispatchUpdateProductsWishlistQueue = (...args: Parameters<typeof updateProductsWishlistQueue>) =>
        dispatch(updateProductsWishlistQueue(...args));
    const dispatchShowOverlay = (...args: Parameters<typeof showOverlay>) => dispatch(showOverlay(...args));
    const dispatchAddToProductsWishlistAction = (...args: Parameters<typeof addToProductsWishlistAction>) =>
        dispatch(addToProductsWishlistAction(...args));
    const dispatchRemoveFromProductsWishlistAction = (...args: Parameters<typeof removeFromProductsWishlistAction>) =>
        dispatch(removeFromProductsWishlistAction(...args));

    const fetch = () => {
        if (isLoggedIn) {
            dispatch(fetchProductsWishlistAction());
        }
    };

    const isProductInWishlist = (productSimpleSku: string): boolean => {
        if (!productSimpleSku) {
            return false;
        }
        return wishlist.some((simple: string) => simple.startsWith(productSimpleSku));
    };

    const add = (productSimpleSku: string, notificationMessages?: WishListNotificationMessages): boolean => {
        if (!isLoggedIn) {
            dispatchUpdateProductsWishlistQueue(ADD_QUEUED_PRODUCT_TO_WISHLIST, productSimpleSku);
            dispatchShowOverlay(OverlayId.LOGIN_AND_REGISTER);
            trackLoginAndRegisterOverlayFomWishlist(productSimpleSku);
            return false;
        }
        dispatchAddToProductsWishlistAction(productSimpleSku, notificationMessages);
        return true;
    };

    const remove = (productSimpleSku: string): boolean => {
        if (!isLoggedIn) {
            dispatchUpdateProductsWishlistQueue(REMOVE_QUEUED_PRODUCT_FROM_WISHLIST, productSimpleSku);
            return false;
        }
        dispatchRemoveFromProductsWishlistAction(productSimpleSku);
        return true;
    };

    const isInProductsWishlist = useCallback(isProductInWishlist, [wishlist]);
    const fetchProductsWishlist = useCallback(fetch, [isLoggedIn]);
    const addToProductsWishlist = useCallback(add, [isLoggedIn]);
    const removeFromProductsWishlist = useCallback(remove, [isLoggedIn]);

    return { fetchProductsWishlist, isInProductsWishlist, addToProductsWishlist, removeFromProductsWishlist };
};

export default useProductsWishlist;
