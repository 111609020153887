import { useCallback } from 'react';
import { ProductInterface } from '../../appshell/appshell_types';
import { useSegmentTracking } from './useSegment';
import { useSegmentTrackingInfo } from './useSegmentTrackingInfo';
import { getContextPageName } from './SegmentTracker';

export const useSegmentProductClickTracking = (baseClickSource = '') => {
    const segmentTracker = useSegmentTracking();
    const { pageType, pageLabel } = useSegmentTrackingInfo();

    return useCallback(
        (product: ProductInterface, clickSource: string = '') => {
            segmentTracker.track('Product Clicked', {
                click_source: clickSource || baseClickSource,
                sku: product.sku,
                position: product.position + 1,
                page_type: pageType,
                page_label: pageLabel,
                pageName: getContextPageName(),
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [segmentTracker, baseClickSource]
    );
};
