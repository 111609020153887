import { FilterType, ListingPageUrlState } from '../../redux/products/types';
import useListingPageUrlState from '../ListingPage/hooks/useListingPageUrlState';
import { SEO_CONSTANTS } from '../ListingPage/services/seo/constant';
import { computeSeoIndexableAndFollow } from '../ListingPage/services/seo';
import { AppliedFilter } from '../Generic/FilterAndSortBox/types';
import useSegments from 'Client/hooks/useSegments';

const getSizeFilter = (filters: AppliedFilter<FilterType>[] = []) =>
    filters.find(filter => filter.type === FilterType.sizes);

const isIndexable = (urlState: ListingPageUrlState): boolean =>
    computeSeoIndexableAndFollow(urlState) === SEO_CONSTANTS.indexable;

export function useSizeSuffix(): boolean {
    const [urlState] = useListingPageUrlState();
    const { botSegment: isBot, hasSearchEngineReferer } = useSegments();
    const sizeFilter = getSizeFilter(urlState.appliedFilters || []);

    return Boolean((isBot || hasSearchEngineReferer) && sizeFilter && isIndexable(urlState));
}

export function getSizeSuffix(urlState: ListingPageUrlState): string {
    const { appliedFilters = [] } = urlState;
    const sizeFilter = getSizeFilter(appliedFilters);
    return sizeFilter?.values[0] || '';
}
