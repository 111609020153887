import styled, { css } from 'styled-components';
import { forTablet, forSemiWideScreens } from '@westwing/ui-kit/breakPoints';
import { header1, header6 } from '@westwing/ui-kit/typography';
import { scroll } from '@westwing/ui-kit/utils';
import LineSeparator from 'Client/components/Generic/LineSeparator';

export const gridItemWidth = '47vw';

export const ProductGridGap = css`
    grid-gap: 10px;

    ${forTablet`
        grid-row-gap: ${({ theme }) => (theme.featureToggles.p100FreistellerImages ? '37px' : '10px')};
                grid-column-gap: 10px;
    `}
`;

export const StyledGrid = styled.div<{ fourColumnPlp?: boolean }>`
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row dense;
    ${ProductGridGap}

    ${({ fourColumnPlp }) =>
        css`
            ${fourColumnPlp &&
            forSemiWideScreens`
                && {
                     grid-template-columns: repeat(4, 1fr);
                }
            `}
            ${forTablet`
                grid-template-columns: repeat(3, 1fr);
            `}
        `}
`;

export const StyledHorizontalGrid = styled.div<{ sliderLength: number }>`
    ${scroll('x', true)}
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    display: grid;
    grid-template-columns: repeat(${({ sliderLength }) => sliderLength}, ${gridItemWidth});
    grid-gap: 5px;
    & > * {
        /* Added back to truncate long titles on product info box. */
        display: inline-block;
        white-space: initial;
    }
    ${({ sliderLength }) => css`
        ${forTablet`
            grid-template-columns: repeat(${sliderLength}, calc(33.125% - 5px));
            grid-gap: 10px;
        `}
    `}
`;

export const Title = styled.h2`
    ${header6}
    color: ${({ theme }) => theme.colors.charcoal100};
    margin: 0.5em 0 0.5em;
    font-weight: normal;
    ${forTablet`
        ${header1}
        grid-area: title;
        display: grid;
        align-self: flex-end;
    `}
`;
export const StyledLineSeparator = styled(LineSeparator)`
    margin-top: 1.4em;
`;
