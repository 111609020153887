import { ListingPageUrlState, FilterType } from 'Client/redux/products/types';
import { Config, HrefLangsDomains } from 'AppShell/appshell_types';

const doesHrefLangContainsLang = (hrefLangsDomains: Partial<HrefLangsDomains> = {}, lang: string): boolean =>
    Object.keys(hrefLangsDomains || {}).some(key => key.includes(lang));

export const getHrefDomain = (config: Config, availableIn?: string): string => {
    if (availableIn && doesHrefLangContainsLang(config.hrefLangsDomains, availableIn)) {
        return config.hrefLangsDomains[availableIn];
    }
    return config.links.url;
};

export const hasPathFilter = (urlState: ListingPageUrlState): boolean => {
    const { appliedFilters } = urlState;
    return appliedFilters.filter(filter => filter.type !== FilterType.query).length > 0;
};
