import { FilterType, ListingPageUrlState } from '../../redux/products/types';
import useListingPageUrlState from '../ListingPage/hooks/useListingPageUrlState';
import { SEO_CONSTANTS } from '../ListingPage/services/seo/constant';
import { computeSeoIndexableAndFollow } from '../ListingPage/services/seo';
import { AppliedFilter } from '../Generic/FilterAndSortBox/types';
import useSegments from 'Client/hooks/useSegments';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';

// regex from https://stackoverflow.com/questions/2332811/capitalize-words-in-string
const firstWordLetter = /(?:^|\s|["'([{])+\S/g;
const getFilters = (filters: AppliedFilter<FilterType>[] = []) =>
    [FilterType.colors, FilterType.materials, FilterType.styles]
        .map(filterType => filters.find(filter => filter.type === filterType))
        .filter(Boolean) as AppliedFilter<FilterType>[];

const isIndexable = (urlState: ListingPageUrlState): boolean =>
    computeSeoIndexableAndFollow(urlState) === SEO_CONSTANTS.indexable;

function getFilterHeadline(urlState: ListingPageUrlState): string {
    const { appliedFilters = [] } = urlState;
    const filters = getFilters(appliedFilters);
    const upperCasedValues = filters.map(f => f.values[0].replace(firstWordLetter, match => match.toLocaleUpperCase()));
    return upperCasedValues.join(', ');
}

function useFilterHeadline(): [boolean, string] {
    const [urlState] = useListingPageUrlState();
    const { botSegment: isBot, hasSearchEngineReferer } = useSegments();
    const hasFilters = getFilters(urlState.appliedFilters || []).length > 0;
    const { shopSeoFilterValueUnderProductTitle: featureToggle } = useFeatureToggle();

    const isVisible = Boolean(
        featureToggle && (isBot || hasSearchEngineReferer) && hasFilters && isIndexable(urlState)
    );
    const headline = isVisible ? getFilterHeadline(urlState) : '';
    return [isVisible, headline];
}

export default useFilterHeadline;
