import { SeoData } from 'Client/redux/seo/types';
import { getNewProductsUrl } from 'Client/urlService';
import { PageType } from 'AppShell/appshell_types/routingTypes';
import { BellaTranslator, Config } from 'AppShell/appshell_types';

export const buildDefaultAvailableIn = (pageType: PageType, config: Config) => {
    const { languages = {} } = config;
    const availableIn = {};
    if (pageType === PageType.NEWPRODUCTS) {
        Object.keys(languages || {}).forEach(key => {
            availableIn[languages[key]] = getNewProductsUrl();
        });
    }
    return availableIn;
};

export const getDefaultSeoData = (type: PageType, t: BellaTranslator, config: Config): SeoData => {
    const seoDefault: SeoData = {
        seoTitle: '',
        seoDescription: '',
        seoText: '',
        mobilePageHeader: '',
        seoHeadline: '',
        availableIn: buildDefaultAvailableIn(type, config),
        similarPages: [],
        seoSlider: null,
        localizedFilters: {},
        similarLinks: [],
    };

    if (type === PageType.NEWPRODUCTS) {
        seoDefault.seoTitle = t('New Arrivals');
        seoDefault.seoDescription = t('New Arrivals');
    }

    if (type === PageType.ALLPRODUCTS) {
        seoDefault.seoTitle = t('Search results');
        seoDefault.seoDescription = t('Search results');
    }

    return seoDefault;
};
