import { FilterType, ListingPageUrlState } from '../../../../redux/products/types';
import { INDEXABLE_PAGE_MULTIPLE_FILTERS_UPPER_LIMIT, INDEXABLE_PATH_FILTERS_SUM_UPPER_LIMIT } from './constant';
import { createSeoDecisionObject } from './createSeoDecisionObject';

export const computeSeoIndexableAndFollow = (listingPageState: ListingPageUrlState) => {
    const { appliedFilters = [], page = 1 } = listingPageState;
    const { shouldIndex, shouldFollow, indexableFiltersSum } = createSeoDecisionObject(listingPageState);

    const isQueryFiltersEmpty = appliedFilters.filter(filter => filter.type === FilterType.query).length === 0;
    const hasNoActiveFiltersViolation = indexableFiltersSum <= INDEXABLE_PATH_FILTERS_SUM_UPPER_LIMIT;
    const isPageIndexableFromMultipleFilter =
        indexableFiltersSum <= 1 || page <= INDEXABLE_PAGE_MULTIPLE_FILTERS_UPPER_LIMIT;
    const constraints = hasNoActiveFiltersViolation && isPageIndexableFromMultipleFilter && isQueryFiltersEmpty;

    const index = shouldIndex && constraints ? 'index' : 'noindex';
    const follow = shouldFollow && constraints ? 'follow' : 'nofollow';
    return `${index},${follow}`;
};
