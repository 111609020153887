import styled from 'styled-components';
import React, { FC, ReactElement } from 'react';
import { forTablet } from '@westwing/ui-kit/breakPoints';
import Product, { GenericProductProps } from './GenericProduct';

const HeroProductWrapperStyled = styled(Product)`
    grid-column-start: span 2;

    width: 100%;
    ${forTablet`
        grid-column-start: auto;
    `}
`;

export type HeroProductProps = Omit<GenericProductProps, keyof { isHeroProduct: boolean }>;

const HeroProduct: FC<HeroProductProps> = (props): ReactElement => (
    <HeroProductWrapperStyled {...props} isHeroProduct />
);

export default HeroProduct;
