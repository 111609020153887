import * as React from 'react';
import styled from 'styled-components';
import { body2, header1, header6 } from '@westwing/ui-kit/typography';
import { forTablet, forIE, forDesktop, forMidDesktop } from '@westwing/ui-kit/breakPoints';
import { Direction, RotationDegrees, StyledArrowIcon } from '@westwing/ui-kit/Icons/StyledIcons';
import BellaHybridLink from '../Generic/Links/BellaHybridLink';

export interface CTAOptions {
    loading?: boolean;
    text: string;
    link: string;
    trackingFn?: () => void;
}

export const Header = styled.div`
    padding: 12px 0 0 20px;
    ${forTablet`
        display: grid;
        grid-template:
            'title ' 30px
            'link ' auto
            / 1fr 90px;
    `}
    ${forMidDesktop`
        padding-top: 30px;
        padding-left: 13px;
    `}
    ${forIE`
        display: flex;
        justify-content: space-between;

        display: -ms-grid;
        -ms-grid-columns: 1fr 90px;
        -ms-grid-rows: 30px 20px;
    `}
`;

export const Title = styled.h2`
    ${header6}
    color: ${({ theme }) => theme.colors.charcoal100};
    line-height: 1.25;
    margin: 0;
    font-weight: normal;
    ${forTablet`
        ${header1}
        margin: 0;
        grid-area: title;
        display: grid;
        align-self: flex-end;
    `}

    ${forIE`
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
`}
`;

const CtaLink = styled(BellaHybridLink)`
    ${body2}
    font-weight: normal;
    color: ${({ theme }) => theme.colors.charcoal100};
    display: block;
    margin-bottom: 1.3em;
    text-decoration: underline;
    ${forTablet`
        grid-area: link;
        text-decoration: underline;
        margin-bottom: 0;
    `}

    ${forIE`
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
`}
`;

export const NavigationWrapper = styled.div`
    display: block;
    ${forTablet`
        grid-area: nav;
        display: grid;
        grid-template:
            'left right' 30px
            / 30px 30px;
        align-self: center;
    `}

    ${forDesktop`
        display:none;
    `}
    ${forIE`
        display: flex;
        -ms-grid-row: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column: 2;
        -ms-grid-column-span: 1;
        justify-content: space-between;
    `}
`;

const NavigationArrow = styled(StyledArrowIcon)<{ direction: Direction; area: string }>`
    grid-area: ${({ area }) => area};
    border-left: 1px solid ${({ theme }) => theme.colors.charcoal100};
    border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal100};
    ${({ direction }) => `${RotationDegrees[direction]};`}
`;

const ArrowButton = styled.button`
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: ${({ theme }) => theme.zIndexSliderArrows};

    ${forDesktop`
        background: ${({ theme }) => theme.colors.white};
        border-radius: 50%;
        border: ${({ theme }) => `1px solid ${theme.colors.charcoal20}`};
    `}

    cursor: pointer;
    ${forIE`

        flex-basis: 49%;
    `}
`;

const LeftArrowButton = styled(ArrowButton)`
    display: none;
    grid-row: 2/3;
    grid-column: 1/3;

    ${forMidDesktop`
        display:block;
    `}

    ${forIE`
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
    `}
`;

const RightArrowButton = styled(ArrowButton)`
    display: none;

    grid-row: 2/3;
    grid-column: 4/6;

    ${forMidDesktop`
        display:block;
    `}

    ${forIE`
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column: 4;
        -ms-grid-column-span: 2;
    `}
`;
const RightArrowWrapper = styled.div`
    padding-right: 4px;
`;

const LeftArrowWrapper = styled.div`
    padding-left: 4px;
`;

export const ContentWrapper = styled.div<{ centerOffset?: number }>`
    position: relative;
    display: grid;
    grid-template-columns: 13px 27px 1fr 27px 13px;
    grid-template-rows: ${({ centerOffset }) => (centerOffset ? `1fr 40px 1fr ${centerOffset}px` : '1fr 40px 1fr;')};
    width: 100%;
    margin-top: 16px;

    ${forDesktop`
        margin-top: 12px;
    `}

    ${forIE`
        display: -ms-grid;
        -ms-grid-columns: 13px 37px 1fr 37px 13px;
    `}
    -ms-grid-rows: ${({ centerOffset }) => (centerOffset ? `auto 40px auto ${centerOffset}px` : '1fr 40px 1fr;')};
`;

export const Content = styled.div`
    grid-row: 1/-1;
    grid-column: 1/-1;
    overflow: hidden;

    ${forMidDesktop`
        grid-column: 2/5;
    `}

    ${forIE`
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        -ms-grid-column:2;
        -ms-grid-column-span:3;
    `}
`;

export type ScrollDirection = Direction.Right | Direction.Left;

export interface HorizontalScrollableElementProps {
    title?: string;
    ctaOptions?: CTAOptions;
    centerOffset?: number;
    showHeader?: boolean;
    showNavArrows?: boolean;
    className?: string;
    arrowClickTrackFunction?: (direction?: ScrollDirection) => void;
    children?: React.ReactNode;
}

const HorizontalScrollableElement = React.forwardRef(
    // eslint-disable-next-line complexity
    (
        {
            title,
            ctaOptions,
            showNavArrows,
            children,
            showHeader,
            className,
            centerOffset,
            arrowClickTrackFunction,
        }: HorizontalScrollableElementProps,
        forwardedRef: React.ForwardedRef<HTMLDivElement>
    ) => {
        const showCta = (ctaOptions && !ctaOptions.loading && ctaOptions.text && ctaOptions.link) as boolean;
        const scrollableContainerRef = React.useRef<HTMLDivElement>(null);

        const [isShowLeftButton, setShowLeftButton] = React.useState(false);
        const [isShowRightButton, setShowRightButton] = React.useState(true);

        const onScroll = (direction: ScrollDirection) => {
            window.requestAnimationFrame(() => {
                if (
                    scrollableContainerRef &&
                    scrollableContainerRef.current &&
                    scrollableContainerRef.current.children
                ) {
                    const container = scrollableContainerRef.current.children[0];
                    const step = scrollableContainerRef.current.scrollWidth * 0.75;
                    const scrollLeftValue =
                        direction === Direction.Left ? container.scrollLeft - step : container.scrollLeft + step;
                    container.scrollLeft = scrollLeftValue;

                    setShowLeftButton(scrollLeftValue > 0);

                    setShowRightButton(!(scrollLeftValue + step > container.scrollWidth));
                }
            });
        };
        const handleClick = (direction: ScrollDirection) => {
            onScroll(direction);
            if (arrowClickTrackFunction) {
                arrowClickTrackFunction(direction);
            }
        };

        return (
            <>
                {showHeader && (
                    <Header className={className}>
                        {title && <Title>{title}</Title>}

                        {ctaOptions && showCta && (
                            <CtaLink to={ctaOptions.link} onClick={ctaOptions.trackingFn}>
                                {ctaOptions.text}
                            </CtaLink>
                        )}
                    </Header>
                )}
                <ContentWrapper centerOffset={centerOffset} ref={forwardedRef}>
                    <Content ref={scrollableContainerRef}> {children}</Content>
                    {showNavArrows && (
                        <>
                            {isShowLeftButton && (
                                <LeftArrowButton onClick={() => handleClick(Direction.Left)}>
                                    <LeftArrowWrapper>
                                        <NavigationArrow size={9} direction={Direction.Left} area={Direction.Left} />
                                    </LeftArrowWrapper>
                                </LeftArrowButton>
                            )}
                            {isShowRightButton && (
                                <RightArrowButton onClick={() => handleClick(Direction.Right)}>
                                    <RightArrowWrapper>
                                        <NavigationArrow size={9} direction={Direction.Right} area={Direction.Right} />
                                    </RightArrowWrapper>
                                </RightArrowButton>
                            )}
                        </>
                    )}
                </ContentWrapper>
            </>
        );
    }
);

export default HorizontalScrollableElement;
