import { ProductImageTagType } from '@westwing/ui-kit/ProductTile';
import useConfig from '../../appshell/hooks/useConfig';
import {
    FormatImageFunction,
    ImageFormattingOptions,
    reformatImage,
    useImageTagFormatter as useUikitImageTagFormatter,
    getShortPath,
} from '@westwing/ui-kit/utils/image';

export const useImageTagFormatter = (
    tag: ProductImageTagType,
    options?: ImageFormattingOptions & { isClubItem?: boolean },
    shouldRemoveText?: boolean
): FormatImageFunction => {
    const { staticCmsHost: shopStaticHost, clubStaticHost } = useConfig();

    const staticCmsHost = options?.isClubItem ? clubStaticHost : shopStaticHost;
    return useUikitImageTagFormatter({ tag, staticCmsHost, options, shouldRemoveText });
};

export const getFormattedBannerImageSrc = (src: string, staticCmsHost: string, webP?: boolean) =>
    reformatImage(getShortPath(src, 'upload'), staticCmsHost, {
        webP,
        excludeDefaultQuality: true,
    });
