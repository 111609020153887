/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { forIE, forMidDesktop, forTablet } from '@westwing/ui-kit/breakPoints';
import TextBox from '@westwing/ui-kit/TextBox';
import { body2, caption1, textLinesLimitClipCss } from '@westwing/ui-kit/typography';
import { PageType, urlStateToWebUrl } from '@westwing/looks-url-service';
import { ObjectFit } from '@westwing/ui-kit/Picture/Image';
import { ErrorBoundary } from '../Generic/ErrorBoundaries';
import ContentfulImage from '../Generic/ContentfulImage';
import Product, { GenericProductProps } from './GenericProduct';
import useInView from 'Client/hooks/dom/useInView';
import { useLogger } from 'AppShell/logger/LoggerProvider';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { LooksInfusion } from 'Client/redux/products/types';
import useGa from 'Client/hooks/useGa';
import { setLDPKnownInfoAction } from 'Client/redux/lookDetail/actions';
import { PartialBy } from 'Utils/types/utility';

const InfusionProductWrapperStyled = styled.div<{ isInfusionMobile: boolean; showInfusion: boolean }>`
    display: grid;
    ${({ isInfusionMobile, showInfusion }) =>
        showInfusion
            ? isInfusionMobile
                ? css`
                      grid-column-start: span 2;
                      grid-template-columns: 1fr 1fr;
                      grid-gap: 5px;
                      ${forTablet`
                        grid-column-start: auto;
                        grid-template-columns: 1fr;
                        grid-gap: 10px;
                    `}
                  `
                : css`
                      ${forTablet`
                        grid-column-start: span 2;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 10px;
                    `}
                  `
            : ''}
`;

const InfusionHolder = styled.div<{ bottomColor: string }>`
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 63px;
    ${({ bottomColor }) => `background: ${bottomColor};`}

    ${forTablet`
        grid-template-rows: 1fr 79px;
    `}
`;

const LooksInfoBox = styled(TextBox)`
    padding: 12px 15px;
    height: 63px;
    text-align: left;
    ${({ theme }) =>
        theme.featureToggles.p100FreistellerImages &&
        css`
            display: flex;
            flex-direction: column;
            justify-content: center;
        `}

    ${forTablet`
        height: 79px;
    `}
`;

const ImageHolder = styled.div`
    position: relative;
`;

const StyledArrow = styled.div<{ columnPosition: string }>`
    position: absolute;
    top: calc(50% - 7px);
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    ${({ columnPosition, theme }) =>
        columnPosition === 'left'
            ? css`
                  right: 0;
                  border-right: 13px solid ${theme.colors.white};
              `
            : css`
                  left: 0;
                  border-left: 13px solid ${theme.colors.white};
              `}
`;

export const Title = styled(TextBox.Header)`
    ${caption1}
    color: ${({ theme }) => theme.colors.white};

    ${textLinesLimitClipCss(1)}
    ${forIE`
        display: block;
    `}
    ${forMidDesktop`
        ${body2}
    `}
`;

export const Cta = styled(TextBox.Cta)`
    ${caption1}
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 0;
    line-height: ${({ theme }) => (theme.featureToggles.p100FreistellerImages ? '20px' : '')};

    ${forMidDesktop`
        ${body2}
        margin-top: 0px;
    `}
`;

export const StyledInfusionProduct = styled.div<{ isInfusionMobile: boolean }>`
    ${({ isInfusionMobile }) =>
        isInfusionMobile
            ? css`
                  ${forTablet`
                    display: none;
                `}
              `
            : css`
                  display: none;
                  ${forTablet`
                    display: block;
                `}
              `}
`;

export type InfusionProductProps = {
    isInfusionMobile: boolean;
    columnPosition: string;
    bottomColor: string;
    looksInfusion: LooksInfusion;
};

const InfusionProduct = ({
    bottomColor,
    looksInfusion,
    columnPosition,
    isInfusionMobile,
}: InfusionProductProps): ReactElement => {
    const logger = useLogger();
    const gaTracking = useGa();
    const t = useBellaTranslator();
    const dispatch = useDispatch();

    const containerRef = React.useRef<HTMLDivElement>(null);
    useInView({
        containerRef,
        options: { threshold: 0.5 },
        onVisibleCallback: () =>
            gaTracking.trackInfusionImpressionEvent({
                action: 'Look infusion - Impression',
                label: looksInfusion.trackingName,
            }),
    });

    const ldpURL = looksInfusion ? urlStateToWebUrl({ pageType: PageType.LDP, look: looksInfusion.slug }) : '#';

    const onClick = () => {
        gaTracking.trackPLP({ action: 'Look infusion - Clicks', label: looksInfusion.trackingName });
        dispatch(setLDPKnownInfoAction(looksInfusion));
    };

    return (
        <ErrorBoundary boundaryName="Infusion-Product" fallbackComponent={null} logger={logger}>
            <StyledInfusionProduct isInfusionMobile={isInfusionMobile} ref={containerRef}>
                <BellaHybridLink
                    to={ldpURL}
                    onClick={onClick}
                    data-testid={`infusion-product-${isInfusionMobile ? 'mobile' : 'desktop'}`}
                >
                    <InfusionHolder bottomColor={bottomColor}>
                        <ImageHolder>
                            <ContentfulImage
                                alt={looksInfusion.name}
                                src={looksInfusion.portraitImg}
                                objectFit={ObjectFit.FILL}
                            />
                            <StyledArrow columnPosition={columnPosition} />
                        </ImageHolder>
                        <LooksInfoBox>
                            <Title as="div">{looksInfusion.name}</Title>
                            <Cta>{t('Shop the Look')}</Cta>
                        </LooksInfoBox>
                    </InfusionHolder>
                </BellaHybridLink>
            </StyledInfusionProduct>
        </ErrorBoundary>
    );
};

export type InfusionProductWrapperProps = GenericProductProps & PartialBy<InfusionProductProps, 'looksInfusion'>;

const InfusionProductWrapper = (props: InfusionProductWrapperProps): ReactElement => {
    const { isInfusionMobile, columnPosition, bottomColor, looksInfusion } = props;
    const showInfusion = !!looksInfusion;

    return (
        <InfusionProductWrapperStyled isInfusionMobile={isInfusionMobile} showInfusion={showInfusion}>
            {columnPosition === 'left' && (
                <>
                    {looksInfusion && (
                        <InfusionProduct
                            isInfusionMobile={isInfusionMobile}
                            bottomColor={bottomColor}
                            looksInfusion={looksInfusion}
                            columnPosition={columnPosition}
                        />
                    )}

                    <Product {...props} />
                </>
            )}
            {(columnPosition === 'right' || columnPosition === 'center') && (
                <>
                    <Product {...props} />
                    {looksInfusion && (
                        <InfusionProduct
                            isInfusionMobile={isInfusionMobile}
                            bottomColor={bottomColor}
                            looksInfusion={looksInfusion}
                            columnPosition={columnPosition}
                        />
                    )}
                </>
            )}
        </InfusionProductWrapperStyled>
    );
};

export default InfusionProductWrapper;
