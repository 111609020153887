/* eslint-disable complexity */
import React from 'react';
import useListingPageUrlState from '../../ListingPage/hooks/useListingPageUrlState';
import { PromotionsConfig } from 'Client/components/Generic/ProductsGrid/types';
import { HeroProduct, Product } from 'Client/components/Product';
import InfusionProduct from 'Client/components/Product/InfusionProduct';
import { LooksInfusion, ProductInterface } from 'Client/redux/products/types';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import GenericPromotionProduct from 'Client/components/Product/GenericPromotionProduct';
import { heroProductsSpotsInGrid } from 'Client/components/Generic/ProductsGrid/heroProducts';
import { ProductList } from 'Common/ga/types';
import useTheme from 'Client/hooks/useTheme';

type FormattedProductProps = {
    looksInfusions?: LooksInfusion[] | null;
    product: ProductInterface;
    products?: ProductInterface[];
    desktopInfusionCounter?: React.MutableRefObject<number>;
    mobileInfusionCounter?: React.MutableRefObject<number>;
    isHero: boolean;
    promotionsConfig?: PromotionsConfig;
    list?: ProductList;
};

const noMobileInfusionZone: number[] = heroProductsSpotsInGrid.reduce((acc: number[], pos) => {
    for (let i = pos - 2; i <= pos + 2; i++) {
        acc.push(i);
    }
    return acc;
}, []);

const inFusionProductsSpotsInGridMobile: number[] = [10, 24, 29, 38, 48, 59, 70, 85];
const insFusionProductsColumnMobile: string[] = [
    'left',
    'left',
    'right',
    'left',
    'left',
    'left',
    'center',
    'right',
    'right',
];

const insFusionProductsSpotsInGridDesktop: number[] = [19, 37, 49, 60, 80];
const insFusionProductsColumnDesktop: string[] = ['left', 'left', 'center', 'center', 'right'];

export const generateNeighbors = (currentPosition: number, desktop: boolean): number[] => {
    let position = currentPosition;
    const gridWidth = desktop ? 3 : 2;
    let gridCol = position % gridWidth;

    // when looks infusion is on the 3rd column, it is rendered on the next row (swaps position with the next index) and behaves like the successive index
    // so we account for this oddity by treating the 3rd column as the next index
    if (gridCol === 2) {
        position += 1;
        gridCol = position % gridWidth;
    }
    const startIdx = Math.max(position - gridCol - gridWidth, 0);
    const endIdx = position + (gridWidth - gridCol - 1) + gridWidth;
    return Array.from({ length: endIdx - startIdx }, (_, i) => startIdx + i);
};

const FormattedProduct: React.FC<FormattedProductProps> = (props): React.ReactElement => {
    const {
        looksInfusions,
        desktopInfusionCounter,
        mobileInfusionCounter,
        product,
        products,
        isHero,
        promotionsConfig,
    } = props;
    const { p100PlpLooksInfusion, p100PlpInfusion } = useFeatureToggle();
    const { position } = product;
    const [listingPageState] = useListingPageUrlState();
    const theme = useTheme();

    if (
        p100PlpLooksInfusion &&
        looksInfusions?.length &&
        products &&
        product.name !== '' &&
        mobileInfusionCounter &&
        desktopInfusionCounter
    ) {
        const mobileCount = mobileInfusionCounter.current;
        const mobilePosition = inFusionProductsSpotsInGridMobile[mobileCount];

        const mobilePositionDiff = position - mobilePosition;
        /**
         * Mobile positioning
         * from the defined position consider
         * 2 previous row, 2 same row (including self)
         * and 2 next position.
         * Skip if it is in vicinity of hero position.
         */
        if (
            products &&
            mobilePosition &&
            mobilePositionDiff >= -2 &&
            mobilePositionDiff <= 3 &&
            !noMobileInfusionZone.includes(position)
        ) {
            const looksInfusionMobile = looksInfusions?.find(l => products[position].sku === l.relatedSku);

            const promotionOffset =
                mobileInfusionCounter.current.valueOf() +
                (promotionsConfig?.mobileCounter.current.valueOf() || 0) +
                heroProductsSpotsInGrid.filter(p => p <= position).length;

            if (looksInfusionMobile || mobilePositionDiff === 1) {
                generateNeighbors(position + promotionOffset, false).forEach(pos =>
                    promotionsConfig?.mobileNoPromoSpots.current.add(Math.max(0, pos - promotionOffset))
                );
                mobileInfusionCounter.current += 1;
            }
            const columnPosition = insFusionProductsColumnMobile[mobileCount];
            const bottomColorMobile = theme.colors.charcoal50;

            return (
                <InfusionProduct
                    {...props}
                    key={position}
                    isInfusionMobile
                    columnPosition={columnPosition}
                    bottomColor={bottomColorMobile}
                    looksInfusion={looksInfusionMobile}
                />
            );
        }

        const desktopCount = desktopInfusionCounter.current;
        const desktopPosition = insFusionProductsSpotsInGridDesktop[desktopCount];

        /**
         * Desktop positioning
         * from the defined position consider
         * 3 previous row, 3 same row (including self)
         * Skip if it collides with hero position.
         */
        const desktopPositionDiff = position - desktopPosition;
        if (desktopPosition && products && !isHero && desktopPositionDiff >= -3 && desktopPositionDiff <= 2) {
            const columnPosition = insFusionProductsColumnDesktop[desktopCount];
            const bottomColorDesktop = theme.colors.charcoal50;
            const looksInfusionDesktop = looksInfusions?.find(
                (l: LooksInfusion) => products[position].sku === l.relatedSku
            );
            const promotionOffset =
                desktopInfusionCounter.current.valueOf() + (promotionsConfig?.desktopCounter.current.valueOf() || 0);

            if (looksInfusionDesktop || desktopPositionDiff === 2) {
                generateNeighbors(position + promotionOffset, true).forEach(pos =>
                    promotionsConfig?.desktopNoPromoSpots.current.add(Math.max(0, pos - promotionOffset))
                );
                desktopInfusionCounter.current += 1;
            }
            return (
                <InfusionProduct
                    {...props}
                    key={position}
                    isInfusionMobile={false}
                    columnPosition={columnPosition}
                    bottomColor={bottomColorDesktop}
                    looksInfusion={looksInfusionDesktop}
                />
            );
        }
    }

    if (p100PlpInfusion && promotionsConfig && listingPageState.page === 1) {
        const { promotions, desktopCounter, desktopNoPromoSpots, mobileCounter, mobileNoPromoSpots } = promotionsConfig;
        const desktopPromo = promotions?.[desktopCounter.current];
        const mobilePromo = promotions?.[mobileCounter.current];

        if (
            !looksInfusions?.length &&
            desktopPromo &&
            mobilePromo &&
            position === desktopPromo.position &&
            position === mobilePromo.position
        ) {
            desktopCounter.current += 1;
            mobileCounter.current += 1;

            return (
                <>
                    <GenericPromotionProduct plpType="desktop" key={`desktop-${position}`} {...desktopPromo} />
                    <GenericPromotionProduct plpType="mobile" key={`mobile-${position}`} {...mobilePromo} />
                    <Product {...props} />
                </>
            );
        }

        // desktop
        if (desktopPromo && looksInfusions?.length) {
            const diff = position - desktopPromo.position;
            if (diff >= -5 && diff <= 5 && !desktopNoPromoSpots.current.has(position)) {
                desktopCounter.current += 1;
                return (
                    <>
                        <GenericPromotionProduct plpType="desktop" key={position} {...desktopPromo} />
                        <Product {...props} />
                    </>
                );
            }
        }

        // mobile
        if (mobilePromo && looksInfusions?.length) {
            const diff = position - mobilePromo.position;
            if (diff >= -5 && diff <= 5 && !mobileNoPromoSpots.current.has(position)) {
                mobileCounter.current += 1;
                return (
                    <>
                        <GenericPromotionProduct plpType="mobile" key={position} {...mobilePromo} />
                        <Product {...props} />
                    </>
                );
            }
        }
    }

    if (isHero) {
        return <HeroProduct {...props} key={position} />;
    }

    return <Product {...props} key={position} />;
};

export default FormattedProduct;
