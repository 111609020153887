import * as React from 'react';
import styled, { css } from 'styled-components';
import { forDesktop, forIE, forMobile } from '@westwing/ui-kit/breakPoints';
import { ObjectFit } from '@westwing/ui-kit/Picture/Image';
import BellaHybridLink from '../Generic/Links/BellaHybridLink';
import { ErrorBoundary } from '../Generic/ErrorBoundaries';
import ContentfulImage from '../Generic/ContentfulImage';
import { GenericPromotion } from 'Client/redux/products/types';
import { useLogger } from 'AppShell/logger/LoggerProvider';
import useInView from 'Client/hooks/dom/useInView';

export interface GenericPromotionProps extends GenericPromotion {
    trackingFn?: () => void;
    impressionTrackingFn?: () => void;
    plpType?: 'desktop' | 'mobile';
}
/* eslint-disable no-nested-ternary */
const InfusionWrapper = styled(BellaHybridLink)<{ $plp?: 'desktop' | 'mobile' }>`
    position: relative;
    width: 100%;
    cursor: pointer;

    & picture {
        height: 100%;
    }

    ${({ $plp }) =>
        $plp === 'desktop'
            ? css`
                  display: none;
                  ${forDesktop`display: block;`}
              `
            : $plp === 'mobile'
            ? css`
                  display: block;
                  ${forDesktop`display: none;`}
              `
            : css`
                  display: block;
              `}
    ${forIE`
        display: none;
    `}
`;
/* eslint-enable no-nested-ternary */

const InfusionButton = styled.div<{ $backgroundColor: string; $color: string }>`
    background: ${({ $backgroundColor }) => $backgroundColor};
    color: ${({ $color }) => $color};
    width: unset;
    height: unset;
    line-height: unset;
    max-width: 100%;
    filter: none;
    text-align: center;
    padding: 0.5em 1em;

    &:hover {
        filter: brightness(1.2);
    }
`;

const ButtonWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.85em;
    ${forMobile`
        font-size: 1em;
    `}
`;

const GenericPromotionProduct: React.FC<GenericPromotionProps> = props => {
    const {
        image,
        ctaText,
        backgroundColor,
        textColor,
        trackingFn = () => {},
        impressionTrackingFn = () => {},
        url,
        plpType,
        objectFit,
    } = props;

    const logger = useLogger();

    const onClick: React.MouseEventHandler<HTMLAnchorElement> = () => {
        trackingFn();
    };

    const containerRef = React.useRef(null);
    useInView({
        containerRef,
        options: { threshold: 0.5 },
        onVisibleCallback: impressionTrackingFn,
    });

    return (
        <ErrorBoundary boundaryName="Promotional Infusion" logger={logger} fallbackComponent={null}>
            <InfusionWrapper data-testid="promotion-product" onClick={onClick} to={url} $plp={plpType}>
                <ContentfulImage
                    src={image.file.url}
                    seoSrc={image.file.seo_url}
                    alt={image.title}
                    objectFit={objectFit || ObjectFit.FILL}
                />
                <ButtonWrapper ref={containerRef}>
                    <InfusionButton $backgroundColor={backgroundColor} $color={textColor}>
                        {ctaText}
                    </InfusionButton>
                </ButtonWrapper>
            </InfusionWrapper>
        </ErrorBoundary>
    );
};

export default GenericPromotionProduct;
