import React, { FC, ReactElement } from 'react';
import UIkitPicture, { jpegBackupSource, ImageFormat } from '@westwing/ui-kit/Picture';
import { getMediaStringValue } from '@westwing/ui-kit/breakPoints';
import { UsePdpSliderImagePropsConfig } from 'Client/components/ProductDetailPage/Gallery/usePdpSliderImageProps';
import { useImageTagFormatter } from 'Client/hooks/imageFormatting';
import { IMAGE_WIDTHS } from '@westwing/ui-kit/utils/image';

export const InfusionImage: FC<UsePdpSliderImagePropsConfig> = ({
    className,
    src,
    alt = '',
    seoSrc,
    imageTag,
}): ReactElement => {
    const formatter = useImageTagFormatter(imageTag, { webP: true });
    const { webpSrc: mobileWebpSrc, jpegSrc: mobileJpegSrc } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.INFUSION_MOBILE_LOW_DENSITY,
        urlKey: 'upload',
    });
    const { webpSrc: mobileWebp2xDpiSrc, jpegSrc: mobileJpeg2xDpiSrc } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.INFUSION_MOBILE_HIGH_DENSITY,
        urlKey: 'upload',
    });
    const { webpSrc: desktopTabletWebpSrc, jpegSrc: desktopTabletJpegSrc } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.INFUSION_DESKTOP_TABLET_LOW_DENSITY,
        urlKey: 'upload',
    });
    const { webpSrc: desktopTabletWebp2xDpiSrc, jpegSrc: desktopTabletJpeg2xDpiSrc } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.INFUSION_DESKTOP_TABLET_HIGH_DENSITY,
        urlKey: 'upload',
    });

    const sources = [
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet', true),
                url: mobileWebpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            mobileJpegSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet', true),
                url: mobileWebp2xDpiSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            mobileJpeg2xDpiSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: desktopTabletWebpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            desktopTabletJpegSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: desktopTabletWebp2xDpiSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            desktopTabletJpeg2xDpiSrc
        ),
    ];

    return (
        <UIkitPicture
            loading="lazy"
            className={className}
            sources={sources}
            fallback={{
                url: seoSrc,
                className,
                alt,
            }}
        />
    );
};

export default InfusionImage;
