import { ListingPageUrlState } from '../../../../redux/products/types';
import { convertListingPageStateToUrl } from '../listingPageUrlService';
import { SEO_CONSTANTS } from './constant';

export const buildPaginationHeadlinkObject = (
    rel: string,
    urlStateForSeo: {
        totalCount: number;
        listingPageSize: number;
        urlState: ListingPageUrlState;
    },
    host: string
) => {
    const { totalCount, listingPageSize, urlState } = urlStateForSeo;
    let number = urlState.page;
    let shouldrender = true;
    switch (rel) {
        case SEO_CONSTANTS.prevRelKeyLink: {
            --number;
            shouldrender = number > 0;
            break;
        }
        case SEO_CONSTANTS.nextRelKeyLink: {
            ++number;
            const totalPages = Math.ceil(totalCount / listingPageSize);
            shouldrender = urlState.page < totalPages;
            break;
        }
        default: {
            break;
        }
    }

    const copyUrlState = { ...urlState, page: number };
    return shouldrender ? { rel, href: convertListingPageStateToUrl(copyUrlState, host) } : {};
};
