import { useLogger } from 'AppShell/logger/LoggerProvider';
import { useEffect, useCallback, RefObject } from 'react';
import { observeElement, ElementObserver, IntersectedCallback, ObserverOptions } from '../../common/helpers/viewport';

export type UseElementIntersection = (
    element: RefObject<HTMLElement> | undefined,
    callback: IntersectedCallback,
    options?: ObserverOptions,
    identifier?: string,
    deps?: ReadonlyArray<any>
) => void;

// eslint-disable-next-line max-params
const useElementIntersection: UseElementIntersection = (element, intersectionCallback, options, identifier, deps) => {
    const logger = useLogger();
    const callback = useCallback(intersectionCallback, [element]);

    useEffect(() => {
        let observer: ElementObserver;
        const elementIsIntersecting: IntersectedCallback = error => {
            callback(identifier);
            if (error) {
                // Temporarily switching to warning till we fix the browser support fallback
                logger.warn(error, { customData: error });
            }
        };

        if (element) {
            observer = observeElement(element, elementIsIntersecting, options);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [element, callback, logger, options, identifier, deps]);
};

export default useElementIntersection;
