import React, { FC, ReactElement, useCallback } from 'react';
import { forIE, isDesktop } from '@westwing/ui-kit/breakPoints';
import styled from 'styled-components';
import { ProductInterface, WishlistTrackingFn } from 'AppShell/appshell_types';
import { ProductList } from 'Common/ga/types';
import { FormattedInfusionData } from 'Client/redux/infusions/types';
import { GenericPromotion, LooksInfusion } from 'Client/redux/products/types';
import { GenericProductProps } from 'Client/components/Product/GenericProduct';
import { PromotionsConfig } from 'Client/components/Generic/ProductsGrid/types';
import useRouter from 'AppShell/hooks/useRouter';
import { useBellaSelector } from 'Client/redux/hooks';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import useGa from 'Client/hooks/useGa';
import normalizeGaProduct from 'Common/ga/normalize';
import FormattedProduct from 'Client/components/Generic/ProductsGrid/FormattedProduct';
import useInfusedProductsList from 'Client/hooks/useInfusedProductsList';
import GenericErrorRetry from 'Client/components/Generic/ErrorPages/GenericErrorRetry';
import GenericPromotionProduct from 'Client/components/Product/GenericPromotionProduct';
import Infusions from 'Client/components/Generic/ProductsGrid/Infusions';
import ErrorBoundary from 'Client/components/Generic/ErrorBoundaries';
import { Product } from 'Client/components/Product';
import { heroProductsSpotsInGrid, isHeroProduct } from 'Client/components/Generic/ProductsGrid/heroProducts';
import { useSegmentProductClickTracking } from '../../../../common/segment/useSegmentProductClickTracking';

export const HorizontalGridProduct = styled(Product)`
    min-height: 272px;
    ${forIE`
        :not(:first-child) {
            margin-left: 10px;
        }
        flex: 1 0 27%;
        max-width: 350px;
    `}
`;

export interface GridBaseProps {
    className?: string;
    gridConfiguration?: number[];
    products: ProductInterface[];
    wishlistProducts?: string[];
    isInline?: boolean;
    list?: ProductList;
    infusions?: FormattedInfusionData;
    eagerImageLoading?: boolean;
    ref?: React.RefObject<HTMLDivElement> | null;
    looksInfusions?: LooksInfusion[] | null;
    promotions?: GenericPromotion[];
    hideNewProductBadge?: boolean;
}

export interface ProductGridProps extends GridBaseProps {
    alternativeProducts?: ProductInterface[];
    showFourColumn?: boolean;
}

interface GetGridProductInterface extends GenericProductProps {
    isInline?: boolean;
    index: number;
    list?: ProductList;
    eagerImageLoading?: boolean;
    looksInfusions?: LooksInfusion[] | null;
    products?: ProductInterface[];
    desktopInfusionCounter?: React.MutableRefObject<number>;
    mobileInfusionCounter?: React.MutableRefObject<number>;
    promotionsConfig?: PromotionsConfig;
}

// eslint-disable-next-line complexity
export const GridProduct = ({
    isInline = false,
    eagerImageLoading = false,
    hideNewProductBadge = false,
    ...productProps
}: GetGridProductInterface): ReactElement => {
    const { product, looksInfusions, mobileInfusionCounter, desktopInfusionCounter, list, promotionsConfig } =
        productProps;
    const {
        location: { pathname },
    } = useRouter();

    const wishlist = useBellaSelector(state => state.header.productsWishlist.products);
    const isAddedToWishlist = wishlist.some((simple: string) => simple.startsWith(product.sku));
    const { p100ImageOnHoverPlp } = useFeatureToggle();
    const { position } = product;

    const gaTracking = useGa();
    const trackSegmentProductClick = useSegmentProductClickTracking();

    const isHero = isHeroProduct(position);
    const defaultTrackingFn = useCallback(() => {
        trackSegmentProductClick(product);

        gaTracking.trackEcProductClick(
            normalizeGaProduct(product, {
                list,
            })
        );
        if (isHero) {
            gaTracking.trackECommerce({
                action: gaTracking.actions.ECommerce.HeroProductClick,
                label: pathname,
            });
        }
    }, [gaTracking, isHero, list, pathname, product, trackSegmentProductClick]);

    const defaultWishListClickTrackingFn: WishlistTrackingFn = ({ action, sku, position }) => {
        gaTracking.trackWishListHeartClick({
            label: `PLP,${sku}`,
            action,
            sku,
            position,
        });

        if (isDesktop() && product.productMoodImage && p100ImageOnHoverPlp && !isAddedToWishlist) {
            gaTracking.trackExperiment({
                action: 'p100ImageOnHoverPlp',
                label: 'Add to Wishlist PLP',
            });
        }
    };

    const productWithTracking = {
        ...productProps.product,
        clickTrackingFn: productProps.product.clickTrackingFn || defaultTrackingFn,
        wishListTrackingFn: productProps.product.wishListTrackingFn || defaultWishListClickTrackingFn,
    };

    const promotionClickTrackingFn = (promotion: GenericPromotion) => {
        gaTracking.trackPLP({
            action: 'Promotional infusion',
            label: promotion.trackingName,
        });
    };
    const promotionImpressionTrackingFn = (promotion: GenericPromotion) => {
        gaTracking.trackPLP({
            action: 'Promotional infusion - impression',
            label: promotion.trackingName,
        });
    };

    const promotionsWithTracking = promotionsConfig?.promotions?.map(promotion => ({
        ...promotion,
        trackingFn: () => promotionClickTrackingFn(promotion),
        impressionTrackingFn: () => promotionImpressionTrackingFn(promotion),
    }));

    // load eagerly images in the first row
    const imageLoading: 'eager' | 'lazy' = eagerImageLoading && position < 3 ? 'eager' : 'lazy';

    const props = {
        ...productProps,
        product: productWithTracking,
        imageLoading,
        promotionsConfig: promotionsConfig ? { ...promotionsConfig, promotions: promotionsWithTracking } : undefined,
        hideNewProductBadge,
    };

    if (isInline) {
        return <HorizontalGridProduct key={`${productProps.index}/${productProps.product.simpleSku}`} {...props} />;
    }
    return (
        <FormattedProduct
            looksInfusions={looksInfusions}
            desktopInfusionCounter={desktopInfusionCounter}
            mobileInfusionCounter={mobileInfusionCounter}
            isHero={isHero}
            {...props}
        />
    );
};

export const ProductsGrid: FC<GridBaseProps> = React.memo(
    ({ products, promotions, isInline, list, infusions, eagerImageLoading = false, hideNewProductBadge = false }) => {
        const productsWithPromotions = useInfusedProductsList(products, heroProductsSpotsInGrid, promotions);

        return (
            <>
                <ErrorBoundary boundaryName="ProductGrid" fallbackComponent={<GenericErrorRetry />}>
                    {productsWithPromotions.map((product, index) =>
                        product.isPromotion ? (
                            <GenericPromotionProduct {...product} key={product.id} />
                        ) : (
                            <GridProduct
                                product={product}
                                key={product.simpleSku}
                                index={index}
                                isInline={isInline}
                                list={list}
                                eagerImageLoading={eagerImageLoading}
                                hideNewProductBadge={hideNewProductBadge}
                            />
                        )
                    )}
                </ErrorBoundary>
                {infusions && <Infusions infusions={infusions} />}
            </>
        );
    }
);
