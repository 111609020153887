/* eslint-disable react-hooks/rules-of-hooks */
import { MouseEvent, useState, useEffect } from 'react';
import useProductsWishlist from 'Client/components/ProductDetailPage/hooks/useProductsWishlist';
import { ProductInterface, WishListTrackingAction } from 'Client/redux/products/types';

export const connectedWishlistBadgeConfigs = (product: ProductInterface) => {
    const { isInProductsWishlist, addToProductsWishlist, removeFromProductsWishlist } = useProductsWishlist();
    const { wishListTrackingFn, addToWishListExperimentFn } = product;
    const [isSelected, setSelected] = useState(isInProductsWishlist(product.simpleSku));

    useEffect(() => {
        setSelected(isInProductsWishlist(product.simpleSku));
    }, [isInProductsWishlist, product]);

    // eslint-disable-next-line complexity
    const handleStateChange = (e: MouseEvent<HTMLElement>, selected: boolean): void => {
        e.preventDefault();
        const isAllowed = selected
            ? addToProductsWishlist(product.simpleSku)
            : removeFromProductsWishlist(product.simpleSku);
        setSelected(!isAllowed ? false : selected);
        const trackingType = !isSelected ? WishListTrackingAction.ADD : WishListTrackingAction.REMOVE;
        if (wishListTrackingFn) {
            wishListTrackingFn({
                action: trackingType,
                sku: product.simpleSku,
                position: product.position,
            });
        }

        if (!isSelected && addToWishListExperimentFn) {
            addToWishListExperimentFn();
        }
    };

    return { isSelected, handleStateChange };
};
