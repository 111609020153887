import { Ratios } from '@westwing/ui-kit/AspectRatio';

export const homePageheroBannerRatios: Ratios = {
    desktop: {
        height: 700,
        width: 1440,
    },
    mobile: {
        height: 1000,
        width: 750,
    },
};

export const homePageSecondaryBannerRatios: Ratios = {
    desktop: {
        height: 500,
        width: 675,
    },
    mobile: {
        height: 894,
        width: 670,
    },
};

export const categorySliderRatios: Ratios = {
    desktop: {
        height: 806,
        width: 606,
    },
    mobile: {
        height: 606,
        width: 456,
    },
};

export const looksSliderRatios: Ratios = {
    desktop: {
        height: 645,
        width: 484,
    },
    mobile: {
        height: 645,
        width: 484,
    },
};

export const looksSliderLandscapeRatios: Ratios = {
    desktop: {
        height: 282,
        width: 423,
    },
    mobile: {
        height: 282,
        width: 423,
    },
};

export const looksDetailPageLandscapeRatios: Ratios = {
    desktop: {
        height: 2,
        width: 3,
    },
    mobile: {
        height: 2,
        width: 3,
    },
};

export const looksPDPortraitRatios: Ratios = {
    desktop: {
        height: 4,
        width: 3,
    },
    mobile: {
        height: 4,
        width: 3,
    },
};

export const homePagePortraitBannerRatios: Ratios = {
    desktop: {
        height: 1192,
        width: 892,
    },
    mobile: {
        height: 892,
        width: 668,
    },
};

export const homePageUGCBannerRatios: Ratios = {
    desktop: {
        height: 594,
        width: 2720,
    },
    mobile: {
        height: 774,
        width: 678,
    },
};

export const homePagePermanentBannerRatios: Ratios = {
    desktop: {
        height: 277,
        width: 616,
    },
    mobile: {
        height: 277,
        width: 616,
    },
};

export const homePagePlacingBannersRatios: Ratios = {
    desktop: {
        height: 490,
        width: 1340,
    },
    mobile: {
        height: 490,
        width: 1340,
    },
};

export const categoryBannerRatios: Ratios = {
    desktop: {
        height: 350,
        width: 1091,
    },
    mobile: {
        height: 754,
        width: 1166,
    },
};

export const InfusionImageRatios: Ratios = {
    desktop: {
        height: 472,
        width: 354,
    },
    mobile: {
        height: 236,
        width: 177,
    },
};
