import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';
import ColorVariantImage from 'Client/components/ProductDetailPage/ProductColorVariants/ColorVariantImage';
import { PlpColorVariants } from 'AppShell/appshell_types';
import { setPDPProductKnownInfoAction } from 'Client/redux/productdetail/actions';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import useGa from 'Client/hooks/useGa';

const SEMI_WIDE_SCREEN_WIDTH = 1224;
const WIDE_SCREEN_WIDTH = 1440;

const Wrapper = styled.div<{ isHover?: boolean }>`
    visibility: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
    transform: translateY(60px);
    transition: transform 100ms linear, visibility 100ms linear;
    padding: 0 15px;
    background: ${({ theme }) => theme.colors.charcoal2};

    ${({ isHover }) =>
        isHover &&
        css`
            ${forDesktop`
                visibility: visible;
                transform: translateY(0px);
            `}
        `}
`;

const VariantsContainer = styled.div`
    display: flex;
    gap: 5px;
    position: relative;
`;

const Bubble = styled(BellaHybridLink)`
    width: 34px;
    height: 34px;
    border: 1px solid ${({ theme }) => theme.colors.charcoal20};
    border-radius: 50%;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
`;

const ColorVariantLink = styled(BellaHybridLink)<{ isDefaultVariant?: boolean }>`
    width: 46px;
    height: 59px;
    padding: 0 3px;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal2};
    position: relative;
    :hover {
        border-bottom-color: ${({ theme }) => theme.colors.charcoal100};
    }

    ${({ isDefaultVariant }) =>
        isDefaultVariant &&
        css`
            border-bottom-color: ${({ theme }) => theme.colors.charcoal100};
        `}

    &:after {
        position: absolute;
        content: ' ';
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.colors.skeleton01};
        top: 0;
        left: 0;
        pointer-events: none;
    }
`;

interface Props {
    isHover: boolean;
    pdpUrl: string;
    defaultVariantSku: string;
    variants: PlpColorVariants[];
    clickFn?: () => void;
}

export const getVisibleVariants = (
    variants: PlpColorVariants[],
    defaultVariantSku: string,
    maxVisibleVariants: number
) => {
    if (variants.length > 1) {
        const visibleVariants = variants.slice(0, maxVisibleVariants);
        if (!visibleVariants.find(item => item.sku === defaultVariantSku)) {
            const defaultVariant = variants.find(variant => variant.sku === defaultVariantSku);
            if (defaultVariant) {
                visibleVariants.splice(0, 1, defaultVariant);
            }
        }
        return visibleVariants;
    }
    return [];
};

const PLPColorVariants: React.FC<Props> = ({ isHover, pdpUrl, defaultVariantSku, variants, clickFn }) => {
    const [maxVisibleVariants, setMaxVisibleVariants] = useState(3);
    const dispatch = useDispatch();
    const gaTracking = useGa();
    const { p100PdpShowMoodImageFirst } = useFeatureToggle();
    useEffect(() => {
        const screenWidth = document.body.clientWidth;
        if (screenWidth >= SEMI_WIDE_SCREEN_WIDTH) {
            setMaxVisibleVariants(4);
        }

        if (screenWidth >= WIDE_SCREEN_WIDTH) {
            setMaxVisibleVariants(5);
        }
    }, []);

    const visibleVariants = getVisibleVariants(variants, defaultVariantSku, maxVisibleVariants).slice(
        0,
        maxVisibleVariants
    );

    const trackClickAction = (sku: string) => {
        gaTracking.trackPLP({ action: 'Color variant on hover - click', label: sku });
    };

    const handleClickAction = (e: React.MouseEvent, variant: PlpColorVariants, sku: string) => {
        if (clickFn) {
            clickFn();
        }
        trackClickAction(sku);
        dispatch(setPDPProductKnownInfoAction(variant, !!p100PdpShowMoodImageFirst));
        e.stopPropagation();
    };

    if (!visibleVariants.length) {
        return null;
    }

    return (
        <Wrapper isHover={isHover}>
            <VariantsContainer>
                {visibleVariants?.map(variant => {
                    const {
                        sku,
                        pdpUrl,
                        productImage: { path, tag, seoUrl },
                    } = variant;
                    const isDefaultVariant = sku === defaultVariantSku;
                    return (
                        <ColorVariantLink
                            key={pdpUrl}
                            to={pdpUrl}
                            onClick={e => handleClickAction(e, variant, sku)}
                            isDefaultVariant={isDefaultVariant}
                        >
                            <ColorVariantImage src={path} imageTag={tag} seoSrc={seoUrl} loading="eager" />
                        </ColorVariantLink>
                    );
                })}
                {variants && variants.length > maxVisibleVariants && (
                    <Bubble to={pdpUrl} onClick={() => trackClickAction(defaultVariantSku)}>
                        +{variants.length - maxVisibleVariants}
                    </Bubble>
                )}
            </VariantsContainer>
        </Wrapper>
    );
};

export default PLPColorVariants;
