import * as React from 'react';
import UIkitPicture, { jpegBackupSource, ImageFormat } from '@westwing/ui-kit/Picture';
import { getMediaStringValue } from '@westwing/ui-kit/breakPoints';
import { UsePdpSliderImagePropsConfig } from '../Gallery/usePdpSliderImageProps';
import { BellaTestIds } from 'Common/qaIds';
import { IMAGE_WIDTHS } from '@westwing/ui-kit/utils/image';
import { ProductImageTag } from '@westwing/ui-kit/ProductTile';
import { useImageTagFormatter } from '../../../hooks/imageFormatting';

export const ColorVariantImage = ({
    className,
    src,
    alt = '',
    imageTag,
    errorFunction,
}: UsePdpSliderImagePropsConfig) => {
    const padding = imageTag !== ProductImageTag.DETAIL;
    const formatter = useImageTagFormatter(imageTag, { webP: true, padding });

    const { webpSrc, jpegSrc } = formatter({
        src,
        imageWidth: IMAGE_WIDTHS.COLOR_VARIANTS,
        imageSizeInfo: {
            size: {
                width: 136,
                height: 139,
            },
        },
    });

    const sources = [
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop', true),
                url: webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop', true),
                url: webpSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop'),
                url: webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop'),
                url: webpSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),
    ];
    return (
        <UIkitPicture
            loading="lazy"
            className={className}
            sources={sources}
            testId={BellaTestIds.pdpColorVariantImage}
            fallback={{
                url: jpegSrc,
                className,
                alt,
            }}
            errorFunction={errorFunction}
        />
    );
};

export default ColorVariantImage;
