import React, { FC } from 'react';
import UIkitPicture, { jpegBackupSource, ImageFormat, PictureProps } from '@westwing/ui-kit/Picture';
import { getMediaStringValue, iphoneXMediaQuery } from '@westwing/ui-kit/breakPoints';
import styled from 'styled-components';
import { contentfulWidthFormatter } from './ContentInfusionsSlider/imageFormatter';
import { useLogger } from 'AppShell/logger/LoggerProvider';

const StyledUIkitPicture = styled(UIkitPicture)`
    width: 100%;
    height: fit-content;
`;

type ContentfulImageProps = {
    className?: string;
    src: string;
    mobileSrc?: string;
    alt?: string;
    loading?: 'eager' | 'lazy';
    seoSrc?: string;
    objectFit?: PictureProps['objectFit'];
    objectPosition?: PictureProps['objectPosition'];
};

enum ImageWidths {
    CONTENTFUL_TABLET_LOW_DENSITY = 375,
    CONTENTFUL_TABLET_HIGH_DENSITY = 750,
    CONTENTFUL_DESKTOP = 750,
    CONTENTFUL_IPHONE_X = 375,
    CONTENTFUL_MOBILE_LOW_DENSITY = 460,
    CONTENTFUL_MOBILE_HIGH_DENSITY = 920,
}

export const ContentfulImage: FC<ContentfulImageProps> = ({
    className,
    mobileSrc,
    src,
    alt = '',
    loading = 'lazy',
    seoSrc,
    objectFit,
    objectPosition,
}) => {
    const logger = useLogger();

    if (!src) {
        return null;
    }

    const logImageErrorWarning = () => {
        logger.warn(`[Contentful] LLP Image with src - "${src}" failed to load`);
    };

    // This makes sure that there is alternate landscape and portrait images on mobile.
    const mobileSource = mobileSrc || src;
    const { webpSrc: iphoneXSrcWebp, jpegSrc: iphoneXSrcJpeg } = contentfulWidthFormatter(
        mobileSource,
        ImageWidths.CONTENTFUL_IPHONE_X
    );

    const { webpSrc: mobileSrcWebp, jpegSrc: mobileSrcJpeg } = contentfulWidthFormatter(
        mobileSource,
        ImageWidths.CONTENTFUL_MOBILE_LOW_DENSITY
    );
    const { webpSrc: mobileSrcWebp2xDpi, jpegSrc: mobileSrcJpeg2xDpi } = contentfulWidthFormatter(
        mobileSource,
        ImageWidths.CONTENTFUL_MOBILE_HIGH_DENSITY
    );

    const { webpSrc: tabletWebpSrc, jpegSrc: tabletJpegSrc } = contentfulWidthFormatter(
        src,
        ImageWidths.CONTENTFUL_TABLET_LOW_DENSITY
    );
    const { webpSrc: tabletWebp2xDpiSrc, jpegSrc: tabletJpeg2xDpiSrc } = contentfulWidthFormatter(
        src,
        ImageWidths.CONTENTFUL_TABLET_HIGH_DENSITY
    );

    const { webpSrc: desktopWebpDpiSrc, jpegSrc: desktopJpegDpiSrc } = contentfulWidthFormatter(
        src,
        ImageWidths.CONTENTFUL_DESKTOP
    );

    const mobileSource2xDpi = jpegBackupSource(
        {
            media: getMediaStringValue('breakpointTablet', true),
            url: mobileSrcWebp2xDpi,
            value: 2,
            format: ImageFormat.WEBP,
        },
        mobileSrcJpeg2xDpi
    );

    const sources = [
        ...jpegBackupSource(
            {
                media: iphoneXMediaQuery,
                url: iphoneXSrcWebp,
                value: 1,
                format: ImageFormat.WEBP,
            },
            iphoneXSrcJpeg
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet', true),
                url: mobileSrcWebp,
                value: 1,
                format: ImageFormat.WEBP,
            },
            mobileSrcJpeg
        ),

        ...mobileSource2xDpi,

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: tabletWebpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            tabletJpegSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: tabletWebp2xDpiSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            tabletJpeg2xDpiSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop'),
                url: desktopWebpDpiSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            desktopJpegDpiSrc
        ),

        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointDesktop'),
                url: desktopWebpDpiSrc,
                value: 2,
                format: ImageFormat.WEBP,
            },
            desktopJpegDpiSrc
        ),
    ];

    return (
        <StyledUIkitPicture
            className={className}
            sources={sources}
            loading={loading}
            fallback={{
                url: seoSrc || desktopWebpDpiSrc,
                className,
                alt,
            }}
            objectFit={objectFit}
            objectPosition={objectPosition}
            errorFunction={logImageErrorWarning}
        />
    );
};

export default ContentfulImage;
