import * as React from 'react';
import { ProductInterface } from 'AppShell/appshell_types';
import { GenericPromotion, ProductsWithPromotions } from 'Client/redux/products/types';

export default function useInfusedProductsList(
    products: ProductInterface[],
    heroPositions: number[] = [],
    promotions: GenericPromotion[] = []
) {
    const productsWithPromotions = React.useMemo(() => {
        const withPromo: ProductsWithPromotions = [...products];

        promotions.forEach(promotion => {
            let { position } = promotion;
            if (heroPositions.includes(position)) {
                position++;
            }
            withPromo.splice(position, 0, { ...promotion, isPromotion: true });
        });

        return withPromo
            .sort((a, b) => a.position - b.position)
            .map((product, index) => ({ ...product, position: index }));
    }, [products, promotions, heroPositions]);
    return productsWithPromotions;
}
