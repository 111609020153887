import React, { ReactElement, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { button, header1, header6, plainTextStyle, textLinesLimitEllipsisCss } from '@westwing/ui-kit/typography';
import { forTablet, forDesktop } from '@westwing/ui-kit/breakPoints';
import TextBox from '@westwing/ui-kit/TextBox';
import { AspectRatio } from '@westwing/ui-kit/AspectRatio';
import InfusionImage from './InfusionImage';
import useGa from 'Client/hooks/useGa';
import useElementIntersection from 'Client/hooks/useElementIntersection';
import ErrorBoundary from 'Client/components/Generic/ErrorBoundaries';
import HorizontalScrollableElement from 'Client/components/ScrollableElement';
import { scroll } from '@westwing/ui-kit/utils';
import { FormattedInfusionData, FormattedInfusionDataItems } from 'Client/redux/infusions/types';
import { InfusionImageRatios } from 'Client/constants/aspectRatios';
import useRouter from 'AppShell/hooks/useRouter';
import { ProductImageTag } from '@westwing/ui-kit/ProductTile';
import useSegmentFeatureTracking, { FeatureImpressionTracking } from 'Common/segment/useSegmentFeatureTracking';
import useInView from 'Client/hooks/dom/useInView';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

interface InfusionProps {
    infusions: FormattedInfusionData;
}

const InfusionContainer = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal20};
    border-top: 1px solid ${({ theme }) => theme.colors.charcoal20};
    margin: 25px -10px 10px;
    grid-row: 3 / 4;
    grid-column: 1 / -1;
    max-width: calc(100vw + 10px);

    ${forTablet`
        margin: 25px 0 25px;
        max-width: 100vw;
    `}
    ${forDesktop`
        margin: 30px 0 30px;
    `}
`;

const StyledPreTitle = styled(TextBox.Header)`
    ${plainTextStyle}
    color: ${({ theme }) => theme.colors.charcoal100};
    max-height: 20px;
    max-width: 155px;
    overflow: hidden;
`;

const StyledTopicTitle = styled(TextBox.Body)`
    ${header6}
    color: ${({ theme }) => theme.colors.charcoal100};
    ${textLinesLimitEllipsisCss(2)}
    ${forTablet`
        ${header1}
    `}
`;

const StyledCTAText = styled(TextBox.Cta)`
    ${button}
    color: ${({ theme }) => theme.colors.charcoal100};
    line-height: 1.4;
    text-decoration: underline;
    margin-bottom: 0;
`;

export const HorizontalScrollContainer = styled.div`
    padding: 0px 0 35px 40px;
    display: flex;
    flex-direction: row;
    scroll-behavior: smooth;
    max-width: 100vw;
    ${scroll('x', true)}
    ${forTablet`
        padding: 0 0 37px;
    `}
`;

const StyledInfusionTextBox = styled(TextBox)`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    ${forTablet`
        margin-top: 10px;
    `}
`;

const InfusionImageWrapper = styled.div`
    min-width: 100px;
    ${AspectRatio}
`;

const LinkedContainer = styled.a`
    padding-right: 10px;
    flex: 1 0 185px;
    ${forTablet`
        padding-right: 0;
        &:not(:first-child) {
            margin-left: 10px;
            padding-right: 0;
        }
        flex-basis: 32.7%;
    `}
    text-decoration: none;
`;

const intersectionOptions = { rootMargin: '0px 0px 50px 0px' };

interface ImageWrapperProps {
    infusion: FormattedInfusionDataItems;
    trackingLabel: string;
}

const ImageWrapper = ({ infusion, trackingLabel }: ImageWrapperProps): ReactElement => {
    const gaTracking = useGa();

    const infusionImageElement = useRef<HTMLDivElement>(null);

    const {
        location: { pathname },
    } = useRouter();
    const deps = useMemo(() => [pathname], [pathname]);

    useElementIntersection(
        infusionImageElement,
        () => {
            gaTracking.trackUsers({
                action: gaTracking.actions.Users.ContentInfusionNavigationImpression,
                label: trackingLabel,
            });
        },
        intersectionOptions,
        undefined,
        deps
    );

    return (
        <InfusionImageWrapper ratio={InfusionImageRatios} ref={infusionImageElement}>
            <InfusionImage
                src={infusion.itemImage}
                seoSrc={infusion.itemImage}
                alt={infusion.itemTopicTitle}
                imageTag={ProductImageTag.DETAIL}
            />
        </InfusionImageWrapper>
    );
};

const defaultSegmentParams: FeatureImpressionTracking = {
    feature_category: SegmentFeatureCategory.Slider,
    feature_name: 'Content Infusion',
};

const Infusions = ({ infusions }: InfusionProps): ReactElement => {
    const { mainTitle, items } = infusions;
    const gaTracking = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const handleClick = (topicTitle: string, currentCount: Number) => {
        gaTracking.trackUsers({
            action: gaTracking.actions.Users.ContentInfusionNavigation,
            label: `${topicTitle} ${currentCount}/${items ? items.length : 0}`,
        });
        trackFeatureInteractions({
            ...defaultSegmentParams,
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: `{${topicTitle} ${currentCount}/${items?.length}}`,
        });
    };

    const showNavArrows = (items && items.length > 3) as boolean;

    const containerRef = React.useRef<HTMLDivElement>(null);
    useInView({
        containerRef,
        onVisibleCallback: () => {
            trackFeatureImpression(defaultSegmentParams);
        },
    });

    return (
        <ErrorBoundary boundaryName="Infusions" fallbackComponent={null}>
            <InfusionContainer ref={containerRef}>
                <HorizontalScrollableElement
                    title={mainTitle}
                    showHeader
                    showNavArrows={showNavArrows}
                    centerOffset={100}
                >
                    <HorizontalScrollContainer>
                        {items &&
                            items.map((infusion, index) => (
                                <LinkedContainer
                                    key={infusion.itemLink}
                                    href={infusion.itemLink}
                                    onClick={() => handleClick(infusion.itemTopicTitle, index + 1)}
                                >
                                    <ImageWrapper
                                        infusion={infusion}
                                        trackingLabel={`${infusion.itemTopicTitle} ${index + 1}/${
                                            items ? items.length : 0
                                        }`}
                                    />
                                    <StyledInfusionTextBox>
                                        <StyledPreTitle>{infusion.itemPreTitle}</StyledPreTitle>
                                        <StyledTopicTitle>{infusion.itemTopicTitle}</StyledTopicTitle>
                                        <StyledCTAText>{infusion.itemCtaText}</StyledCTAText>
                                    </StyledInfusionTextBox>
                                </LinkedContainer>
                            ))}
                    </HorizontalScrollContainer>
                </HorizontalScrollableElement>
            </InfusionContainer>
        </ErrorBoundary>
    );
};

export default Infusions;
