import buildUrl from 'build-url';
import { convertListingPageStateToUrl } from '../listingPageUrlService';
import { SEO_CONSTANTS } from './constant';
import { getHrefDomain, hasPathFilter } from './utils';
import { FilterType, ListingPageUrlState } from 'Client/redux/products/types';
import { LanguageLocalizedFilters, SeoData } from 'Client/redux/seo/types';
import { PageType } from 'AppShell/appshell_types/routingTypes';
import { PdpSeoData } from 'Client/redux/productdetail/types';
import { AppliedFilter } from 'Client/components/Generic/FilterAndSortBox/types';
import { Config } from 'AppShell/appshell_types';

export type MetaLinkType = {
    rel: string;
    hrefLang?: string;
    href: string;
};

function translateAppliedFilters(
    { appliedFilters }: ListingPageUrlState,
    localizedFilter: LanguageLocalizedFilters
): AppliedFilter<FilterType>[] {
    return appliedFilters
        .filter(filter => localizedFilter && localizedFilter[filter.name])
        .map(filter => ({ ...filter, values: localizedFilter[filter.name].values } as AppliedFilter<FilterType>));
}

const createHrefLangPLPUrlState = (
    urlState: ListingPageUrlState,
    availableIn: string,
    localizedFilter?: LanguageLocalizedFilters,
    categoryAvailableIn?: string
    // eslint-disable-next-line max-params
) => {
    const isNewProductPLPWithCategoryFilter =
        urlState.listingPageType === PageType.NEWPRODUCTS &&
        urlState.appliedFilters.some(({ type }) => type === FilterType.categories);
    const baseHrefLangState = {
        ...urlState,
        page: hasPathFilter(urlState) ? 1 : urlState.page,
        sortingParams: {},
        search: undefined,
        appliedFilters: translateAppliedFilters(urlState, localizedFilter || {}),
    };

    if (isNewProductPLPWithCategoryFilter) {
        const [categoryFilters] = urlState.appliedFilters.filter(filter => filter.type === FilterType.categories);
        const categoryFilter = {
            name: '',
            type: FilterType.categories,
            categoryFilters,
            values: [availableIn],
        };
        baseHrefLangState.appliedFilters.push(categoryFilter);
    } else {
        baseHrefLangState.listingPageKey = availableIn + (categoryAvailableIn ? `/${categoryAvailableIn}` : '');
    }

    return baseHrefLangState;
};

// eslint-disable-next-line max-params
const buildAlternateHrefs = (
    seoData: SeoData,
    urlState: ListingPageUrlState,
    config: Config,
    p100DisabledHreflangsCountries?: string[]
    // eslint-disable-next-line max-params
) => {
    const { availableCountryCodes } = config;
    const allAvailableIn = seoData.availableIn;
    const allTranslatedFilters = seoData.localizedFilters;
    const disabledHreflangsInCountries = p100DisabledHreflangsCountries ?? [];
    return Object.keys(allAvailableIn)
        .filter(country => !disabledHreflangsInCountries.includes(country.toUpperCase()))
        .map(country => {
            const hrefLangCountry = availableCountryCodes[country];

            const availableIn = allAvailableIn[country];
            let categoryAvailableIn = '';

            if (seoData.categoryAvailableIn) {
                categoryAvailableIn = seoData.categoryAvailableIn[country];
            }
            const translatedFilter = allTranslatedFilters[country];
            const seoHrefLangPLPUrlState = createHrefLangPLPUrlState(
                urlState,
                availableIn,
                translatedFilter,
                categoryAvailableIn
            );

            return {
                rel: SEO_CONSTANTS.alternateKeyLink,
                hrefLang: hrefLangCountry,
                href: convertListingPageStateToUrl(seoHrefLangPLPUrlState, getHrefDomain(config, country)),
            };
        });
};

// Germany has a special casus due to default germany region `de` as a hreflang
// And a de-DE, and de-CH for regional one
const buildDefaultDEHrefLang = (
    { availableIn, localizedFilters, categoryAvailableIn }: SeoData,
    urlState: ListingPageUrlState,
    config: Config
) => {
    if (!availableIn.de) {
        return [];
    }
    return [
        {
            rel: SEO_CONSTANTS.alternateKeyLink,
            hrefLang: 'de',
            href: convertListingPageStateToUrl(
                createHrefLangPLPUrlState(urlState, availableIn.de, localizedFilters?.de, categoryAvailableIn?.de),
                getHrefDomain(config, 'de')
            ),
        },
    ];
};

type CanonicalObject = {
    rel: string;
    href: string;
};
export const buildCanonicalObject = (
    urlState: ListingPageUrlState,
    seoData: SeoData,
    host: string
): CanonicalObject => {
    const copyUrlState = {
        ...urlState,
        search: undefined,
        sortingParams: {},
    };

    const href = seoData.canonical || convertListingPageStateToUrl(copyUrlState, host);
    return { rel: SEO_CONSTANTS.canonicalKeyLink, href };
};

// eslint-disable-next-line max-params
export const hrefLang = (
    seoData: SeoData | undefined,
    config: Config,
    urlState: ListingPageUrlState,
    p100DisabledHreflangsCountries?: string[]
    // eslint-disable-next-line max-params
) => {
    if (!seoData) {
        return [];
    }

    const hasAvailableIn = Object.keys(seoData?.availableIn || {}).length > 0;

    if (hasAvailableIn) {
        if (hasPathFilter(urlState) && urlState.page > 1) {
            return [];
        }

        return [
            ...buildDefaultDEHrefLang(seoData, urlState, config),
            ...buildAlternateHrefs(seoData, urlState, config, p100DisabledHreflangsCountries),
        ];
    }

    return [];
};

function toShortCountryCode(code: string) {
    if (code.length === 2) {
        return code.toLocaleLowerCase();
    }

    if (code.indexOf('-') !== -1) {
        return code.split('-')[1].toLocaleLowerCase();
    }

    return 'de';
}
const LinkCanonical = (config: Config, path: string): MetaLinkType => ({
    rel: SEO_CONSTANTS.canonicalKeyLink,
    href: buildUrl(getHrefDomain(config), { path }),
});

type LinkHrefLangProp = {
    config: Config;
    countryTLD: string;
    countryCodeISO: string;
    path: string;
};

const LinkHrefLang = ({ config, countryTLD, countryCodeISO, path }: LinkHrefLangProp): MetaLinkType => ({
    rel: SEO_CONSTANTS.alternateKeyLink,
    hrefLang: countryCodeISO,
    href: buildUrl(getHrefDomain(config, countryTLD), { path }),
});

export const pdpCanonical = (seoData: PdpSeoData, config: Config) => {
    const hasAvailableIn = (Object.keys((seoData || { availableIn: {} }).availableIn) || []).length > 0;

    if (!seoData || !hasAvailableIn) {
        return [];
    }

    const { availableIn } = seoData;
    const canonicalPath = availableIn[toShortCountryCode(config.countryCode)];

    return canonicalPath ? [LinkCanonical(config, canonicalPath)] : [];
};

export const pdpHrefLang = (seoData: PdpSeoData, config: Config, p100EnablePtHreflang?: boolean): MetaLinkType[] => {
    const hasAvailableIn = (Object.keys((seoData || { availableIn: {} }).availableIn) || []).length > 0;
    const { availableCountryCodes } = config;

    if (!seoData || !hasAvailableIn) {
        return [];
    }

    const { availableIn } = seoData;
    if (availableIn.pt && !p100EnablePtHreflang) {
        delete availableIn.pt;
    }

    const defaultLinks = [
        availableIn.de && LinkHrefLang({ config, countryTLD: 'de', countryCodeISO: 'de', path: availableIn.de }),
    ].filter(Boolean) as MetaLinkType[];
    const alternateHrefs = Object.keys(availableIn).map(countryTLD => {
        const countryCodeISO = availableCountryCodes[countryTLD];
        const countrySpecificPathname = availableIn[countryTLD];
        return LinkHrefLang({ config, countryTLD, countryCodeISO, path: countrySpecificPathname });
    });

    return [...defaultLinks, ...alternateHrefs];
};

export const homePageHrefLang = (config: Config, p100HreflangPtOnHome?: boolean): MetaLinkType[] => {
    const canonicalHref = config.links.url;
    const canonicalLinkObject = { rel: SEO_CONSTANTS.canonicalKeyLink, href: canonicalHref };
    const defaultDEHrefLang = {
        rel: SEO_CONSTANTS.alternateKeyLink,
        hrefLang: 'de',
        href: config.hrefLangsDomains.de,
    };

    const alternateHrefs = Object.keys(config.availableCountryCodes)
        .map(country => {
            const hrefLangCountry = config.availableCountryCodes[country];
            if (p100HreflangPtOnHome || hrefLangCountry !== 'pt') {
                return {
                    rel: SEO_CONSTANTS.alternateKeyLink,
                    hrefLang: hrefLangCountry,
                    href: buildUrl(getHrefDomain(config, country), { path: '/' }),
                };
            }
            return null;
        })
        .filter(Boolean) as MetaLinkType[];

    return [canonicalLinkObject, defaultDEHrefLang, ...alternateHrefs];
};
